import React from "react";
import { Spinner } from "react-bootstrap";
import "../App.css";

export default function SiteLoad() {
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "89vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom:"100vh"
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <h4 style={{ marginRight: "1rem" }}>Laddar Stipendly</h4>

          <Spinner animation="border" role="status">
            <span className="visually-hidden">Laddar...</span>
          </Spinner>
        </div>
      </div>
    </>
  );
}
