import React, { createContext, useContext, useState, useEffect } from "react";
import { auth, db, methods } from "../firebase";
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  class Profile {
    constructor(
      Q1,
      Q2,
      Q3,
      Q4,
      Q5,
      Q6,
      Q7,
      Q8,
      Q9,
      Q10,
      Q11,
      Q12,
      Q13,
      Q14,
      Q15,
      Q16,
      payed,
      liked
    ) {
      this.Q1 = Q1;
      this.Q2 = Q2;
      this.Q3 = Q3;
      this.Q4 = Q4;
      this.Q5 = Q5;
      this.Q6 = Q6;
      this.Q7 = Q7;
      this.Q8 = Q8;
      this.Q9 = Q9;
      this.Q10 = Q10;
      this.Q11 = Q11;
      this.Q12 = Q12;
      this.Q13 = Q13;
      this.Q14 = Q14;
      this.Q15 = Q15;
      this.Q16 = Q16;
      this.payed = payed;
      this.liked = liked;
    }
    toString() {
      return (
        this.name +
        ", " +
        this.gender +
        ", " +
        this.city +
        ", " +
        this.school +
        ", " +
        this.payed +
        ", " +
        this.lan +
        ", " +
        this.kommun
      );
    }
  }

  const profileConverter = {
    toFirestore: (profile) => {
      return {
        Q1: profile.Q1,
        Q2: profile.Q2,
        Q3: profile.Q3,
        Q4: profile.Q4,
        Q5: profile.Q5,
        Q6: profile.Q6,
        Q7: profile.Q7,
        Q8: profile.Q8,
        Q9: profile.Q9,
        Q10: profile.Q10,
        Q11: profile.Q11,
        Q12: profile.Q12,
        Q13: profile.Q13,
        Q14: profile.Q14,
        Q15: profile.Q15,
        Q16: profile.Q16,
        payed: profile.payed,
        liked: profile.liked,
      };
    },
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      return new Profile(
        data.Q1,
        data.Q2,
        data.Q3,
        data.Q4,
        data.Q5,
        data.Q6,
        data.Q7,
        data.Q8,
        data.Q9,
        data.Q10,
        data.Q11,
        data.Q12,
        data.Q13,
        data.Q14,
        data.Q15,
        data.Q16,
        data.payed,
        data.liked
      );
    },
  };

  function signup(email, password) {
    return methods.createUserWithEmailAndPassword(auth, email, password);
  }

  function createProfile(
    selectedQ1,
    selectedQ2,
    selectedQ3,
    selectedQ4,
    selectedQ5,
    selectedQ6,
    selectedQ7,
    selectedQ8,
    selectedQ9,
    selectedQ10,
    selectedQ11,
    selectedQ12,
    selectedQ13,
    selectedQ14,
    selectedQ15,
    selectedQ16,
    payed,
    liked,
    matches
  ) {
    let Q1 = [selectedQ1.value];
    let Q2 = [selectedQ2.value];
    let Q3 = [];
    selectedQ3.map((o) => Q3.push(o.value));
    let Q4 = [];
    selectedQ4.map((o) => Q4.push(o.value));
    let Q5 = [];
    selectedQ5.map((o) => Q5.push(o.value));
    let Q6 = [];
    selectedQ6.map((o) => Q6.push(o.value));
    let Q7 = [];
    selectedQ7.map((o) => Q7.push(o.value));
    let Q8 = [selectedQ8.value];
    let Q9 = [selectedQ9.value];
    let Q10 = [selectedQ10.value];
    let Q11 = [selectedQ11.value];
    let Q12 = [selectedQ12.value];
    let Q13 = [selectedQ13.value];
    let Q14 = [selectedQ14.value];
    let Q15 = [selectedQ15.value];
    let Q16 = [];
    selectedQ16.map((o) => Q16.push(o.value));

    console.log(matches)

    return setDoc(doc(db, "users", currentUser.uid), {
      Q1: Q1,
      Q2: Q2,
      Q3: Q3,
      Q4: Q4,
      Q5: Q5,
      Q6: Q6,
      Q7: Q7,
      Q8: Q8,
      Q9: Q9,
      Q10: Q10,
      Q11: Q11,
      Q12: Q12,
      Q13: Q13,
      Q14: Q14,
      Q15: Q15,
      Q16: Q16,
      payed: payed,
      liked: liked,
      matches: matches,
    });
  }

  async function getProfile(
    setSelectedQ1,
    setSelectedQ2,
    setSelectedQ3,
    setSelectedQ4,
    setSelectedQ5,
    setSelectedQ6,
    setSelectedQ7,
    setSelectedQ8,
    setSelectedQ9,
    setSelectedQ10,
    setSelectedQ11,
    setSelectedQ12,
    setSelectedQ13,
    setSelectedQ14,
    setSelectedQ15,
    setSelectedQ16,
    setPayed,
    setLiked
  ) {
    const ref = doc(db, "users", currentUser.uid).withConverter(profileConverter);
    const docSnap = await getDoc(ref);
    const profile = docSnap;

    if (profile.data().name !== null) {
      let emptyarray = [];

      setSelectedQ1({
        value: profile.data().Q1[0],
        label: profile.data().Q1[0],
      });
      setSelectedQ2({
        value: profile.data().Q2[0],
        label: profile.data().Q2[0],
      });

      emptyarray = [];
      profile.data().Q3.map((o) => emptyarray.push({ value: o, label: o }));
      setSelectedQ3(emptyarray);

      emptyarray = [];
      profile.data().Q4.map((o) => emptyarray.push({ value: o, label: o }));
      setSelectedQ4(emptyarray);

      emptyarray = [];
      profile.data().Q5.map((o) => emptyarray.push({ value: o, label: o }));
      setSelectedQ5(emptyarray);

      emptyarray = [];
      profile.data().Q6.map((o) => emptyarray.push({ value: o, label: o }));
      setSelectedQ6(emptyarray);

      emptyarray = [];
      profile.data().Q7.map((o) => emptyarray.push({ value: o, label: o }));
      setSelectedQ7(emptyarray);

      setSelectedQ8({
        value: profile.data().Q8[0],
        label: profile.data().Q8[0],
      });

      setSelectedQ9({
        value: profile.data().Q9[0],
        label: profile.data().Q9[0],
      });

      setSelectedQ10({
        value: profile.data().Q10[0],
        label: profile.data().Q10[0],
      });

      setSelectedQ11({
        value: profile.data().Q11[0],
        label: profile.data().Q11[0],
      });

      setSelectedQ12({
        value: profile.data().Q12[0],
        label: profile.data().Q12[0],
      });
      setSelectedQ13({
        value: profile.data().Q13[0],
        label: profile.data().Q13[0],
      });
      setSelectedQ14({
        value: profile.data().Q14[0],
        label: profile.data().Q14[0],
      });
      setSelectedQ15({
        value: profile.data().Q15[0],
        label: profile.data().Q15[0],
      });

      emptyarray = [];
      profile.data().Q16.map((o) => emptyarray.push({ value: o, label: o }));
      setSelectedQ16(emptyarray);

      setPayed(profile.data().payed);

      emptyarray = [];
      profile.data().liked.map((o) => emptyarray.push(o));
      setLiked(emptyarray);
    }

    return profile;
  }

  function updatePayed(pay) {
    const ref = doc(db, "users", currentUser.uid).withConverter(profileConverter);

    return updateDoc(ref, {
      payed: pay,
    });
  }

  async function getLiked() {
    const ref = doc(db, "users", currentUser.uid).withConverter(profileConverter);

    const docSnap = await getDoc(ref, "payed");
    return docSnap.data().liked
  }

  async function getPayed() {
    const ref = doc(db, "users", currentUser.uid).withConverter(profileConverter);

    const docSnap = await getDoc(ref, "payed");
    return docSnap.data().payed;
  }

  function login(email, password) {
    return methods.signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    return methods.signOut(auth);
  }

  function resetPassword(email) {
    return methods.sendPasswordResetEmail(auth, email);
  }

  function updateEmail(email) {
    return methods.updateEmail(currentUser, email);
  }

  function updatePassword(password) {
    return methods.updatePassword(currentUser, password);
  }

  function sendEmailVerification() {
    return methods.sendEmailVerification(auth.currentUser);
  }

  function reauthenticateWithCredential(userProvidedPassword) {
    const credential = methods.EmailAuthProvider.credential(
      currentUser.email,
      userProvidedPassword
    );

    return methods.reauthenticateWithCredential(currentUser, credential);
  }

  useEffect(() => {
    const unsubscribe = methods.onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    signup,
    login,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    sendEmailVerification,
    createProfile,
    getProfile,
    profileConverter,
    reauthenticateWithCredential,
    updatePayed,
    getPayed,
    getLiked,
  };

  return (
    <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>
  );
}
