export const format = (s) => {
    if (s === 0) {
      return "Varierande";
    } else {
      return s.toString().replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, " ") + " kr";
    }
  }

export const tags = (obj, separator) => {
    var arr = [];

    if (obj[11] === "ja") {
      obj[11] = "Studera utomlands";
    }
    if (obj[12] === "ja") {
      obj[12] = "Studera en hel utbildning utomlands";
    }
    if (obj[14] !== undefined) {
      const upp = obj[14];
      obj[14] = "Skriva uppsats inom " + upp;
    }

    for (var key in obj) {
      if (obj[key] !== undefined) {
        if (obj[key].length !== 0) {
          arr.push(obj[key].trim());
        }
      }
    }

    if (arr.length === 0) {
      return "";
    } else {
      return "Taggar: " + arr.join(separator || ", ");
    }
  }