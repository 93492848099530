import React from "react";
import "../App.css";

export default function Article({ subject, header, paragraph, img, link, pos }) {
  return (
    <div class="col-md-6 mb-4" style={{fontFamily: "Poppins"}}>
      <div
        class="row g-0 border rounded overflow-hidden flex-md-row shadow-sm h-md-250 position-relative"
        style={{ background: "white", height:"100%" }}
      >
        <div class="col p-4 d-flex flex-column position-static" >
          <strong class="d-inline-block mb-2 text-success">{subject}</strong>
          <h3 class="mb-0">{header}</h3>
          <br/>
          <p class="card-text mb-auto">{paragraph}</p>
          <a rel="noreferrer" href={link} class="stretched-link">
            Fortsätt Läsa
          </a>
        </div>
        <div class="col-auto d-none d-xl-block">
          <img
            class="bd-placeholder-img"
            width="200"
            height="100%"
            src={img}
            aria-label="Placeholder: Thumbnail"
            preserveAspectRatio="xMidYMid slice"
            focusable="false"
            style={{ objectFit: "cover", objectPosition: pos }}
          />
        </div>
      </div>
    </div>
  );
}
