import React, { useRef, useState } from "react";
import { useAuth } from "../contexts/AuthContext.js";
import { Form, Button, Alert, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, updateProfile } from "firebase/auth";
import ReCAPTCHA from "react-google-recaptcha";

export default function Register() {
  const emailRef = useRef();
  const nameRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { signup } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [verified, setVerified] = useState(false);

  const onChange = (value) => {
    setVerified(value);
  };

  async function handleSubmit(e) {
    e.preventDefault();

    if (verified) {
      if (passwordRef.current.value !== passwordConfirmRef.current.value) {
        return setError("Lösenorden matchar inte.");
      }
      if (nameRef.current.value.length > 100) {
        return setError("Namnet får inte vara längre än 100 bokstäver.");
      }
      if (passwordRef.current.value.length > 100) {
        return setError("Lösenordet får inte vara längre än 100 bokstäver.");
      }
      if (emailRef.current.value.length > 100) {
        return setError("E-posten får inte vara längre än 100 bokstäver.");
      }

      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      } else {
        try {
          setError("");
          setLoading(true);
          await signup(emailRef.current.value, passwordRef.current.value);

          const auth = getAuth();
          await updateProfile(auth.currentUser, {
            displayName: nameRef.current.value,
          });
          navigate("/skapa-profil");
        } catch (e) {
          if (
            e.code === "auth/credential-already-in-use" ||
            e.code === "auth/email-already-in-use"
          ) {
            setError("E-posten är redan registrerad.");
          } else if (e.code === "auth/weak-password") {
            setError("Lösenordet måste innehålla 6 karaktärer.");
          } else {
            setError("Kunde inte skapa ett konto.");
          }
        }
      }
    } else {
      setError("Verifiering av ReCAPTCHA krävs.");
    }
    setValidated(true);
    setLoading(false);
  }
  return (
    <>
      <div
        className="Register"
        style={{
          minHeight: "90vh",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
          position: "relative",
          flex: "1",
        }}
      >
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          style={{ maxWidth: "25rem", width: "75vw" }}
        >
          <div style={{ textAlign: "center", margin: "2rem" }}>
            <h3>
              Skapa <span class="red-text">konto</span>
            </h3>
          </div>
          {error && <Alert variant="danger">{error}</Alert>}

          <div class="form-outline mb-4">
            <Form.Group id="name" as={Col} md="20" controlId="validationCustom01">
              <Form.Label>Namn</Form.Label>
              <Form.Control required type="name" ref={nameRef} placeholder="Namn" />
              <Form.Control.Feedback type="invalid">
                Måste fyllas i.
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          <div class="form-outline mb-4">
            <Form.Group id="email" as={Col} md="20" controlId="validationCustom02">
              <Form.Label>E-post</Form.Label>
              <Form.Control required type="email" ref={emailRef} placeholder="E-post" />
              <Form.Control.Feedback type="invalid">
                Måste fyllas i.
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          <div class="form-outline mb-4">
            <Form.Group id="password" as={Col} md="20" controlId="validationCustom03">
              <Form.Label>Lösenord</Form.Label>
              <Form.Control
                required
                type="password"
                ref={passwordRef}
                placeholder="Lösenord"
              />
              <Form.Control.Feedback type="invalid">
                Måste fyllas i.
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          <div class="form-outline mb-4">
            <Form.Group
              id="password-confirm"
              as={Col}
              md="20"
              controlId="validationCustom04"
            >
              <Form.Label>Återupprepa Lösenord</Form.Label>
              <Form.Control
                type="password"
                ref={passwordConfirmRef}
                placeholder="Lösenord"
                required
              />
              <Form.Control.Feedback type="invalid">
                Måste fyllas i.
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHAV2_SITE_KEY}
            onChange={onChange}
            hl={"sv"}
            class="mb-3"
          />

          <Button
            disabled={loading}
            type="submit"
            variant="flat"
            class="btn btn-primary btn-block mb-3"
            className="button"
          >
            Registrera
          </Button>

          <div class="text-center" style={{ paddingTop: "5%" }}>
            <p>
              Har du redan ett konto? <Link to="/logga-in">Logga in</Link>
            </p>
          </div>
        </Form>
      </div>
    </>
  );
}
