import { isMobile } from "react-device-detect";

export const cities = [
  { value: "Göteborg", label: "Göteborg" },
  { value: "Helsingborg", label: "Helsingborg" },
  { value: "Jönköping", label: "Jönköping" },
  { value: "Linköping", label: "Linköping" },
  { value: "Malmö", label: "Malmö" },
  { value: "Norrköping", label: "Norrköping" },
  { value: "Stockholm", label: "Stockholm" },
  { value: "Uppsala", label: "Uppsala" },
  { value: "Västerås", label: "Västerås" },
  { value: "Örebro", label: "Örebro" },
];

export const fakultet = [
  {
    value: "Annat/Inte applicerbart",
    label: "Annat/Inte applicerbart",
  },
  {
    value: "",
    label: "--- Alternativ ---",
    disabled: true,
  },
  { value: "Filosofiska fakulteten", label: "Filosofiska fakulteten" },
  { value: "Humanistiska fakulteten", label: "Humanistiska fakulteten" },
  { value: "Juridiska fakulteten", label: "Juridiska fakulteten" },
  { value: "Medicinska fakulteten", label: "Medicinska fakulteten" },
  {
    value: "Samhällsvetenskapliga fakulteten",
    label: "Samhällsvetenskapliga fakulteten",
  },
  {
    value: "Tekniska/Naturvetenskapliga fakulteten",
    label: "Tekniska eller Naturvetenskapliga fakulteten",
  },
  { value: "Konstnärliga fakulteten", label: "Konstnärliga fakulteten" },
];

export const uppsats = [
  {
    value: "Annat/Inte applicerbart",
    label: "Annat/Inte applicerbart",
  },
  {
    value: "",
    label: "--- Alternativ ---",
    disabled: true,
  },
  { value: "Belysningsteknik", label: "Belysningsteknik" },
  { value: "Biokemi", label: "Biokemi" },
  { value: "Byggmästeri", label: "Byggmästeri" },
  { value: "Digital marknadsföring", label: "Digital marknadsföring" },
  { value: "Energiteknik", label: "Energiteknik" },
  { value: "Fastighet", label: "Fastighet" },
  { value: "Försäkring", label: "Försäkring" },
  { value: "Genetik", label: "Genetik" },
  { value: "IT", label: "IT" },
  { value: "Kvalitet", label: "Kvalitet" },
  { value: "Lean", label: "Lean" },
  { value: "Logistik", label: "Logistik" },
  { value: "Miljö", label: "Miljö" },
  { value: "Skogsbruk", label: "Skogsbruk" },
  { value: "Transportekonomi", label: "Transportekonomi" },
];

export const studieomrade = [
  { value: "Apotekare", label: "Apotekare" },
  { value: "Arkitektur", label: "Arkitektur" },
  { value: "Design & Formgivning", label: "Design & Formgivning" },
  {
    value: "Ekonomi, Finans & Företagande",
    label: "Ekonomi / Finans / Företagande / Redovisning / Förvaltning",
  },
  { value: "Fastighetsmäklare", label: "Fastighetsmäklare" },
  {
    value: "Humaniora & Filosofi",
    label: "Filosofi / Genusvetenskap / Konstvetenskap",
  },
  { value: "Hantverk & Slöjd", label: "Hantverk & Slöjd" },
  { value: "Historia", label: "Historia / Arkeologi" },
  { value: "Jordbruk/Skogsbruk/Lantbruk", label: "Jordbruk / Skogsbruk / Lantbruk" },
  {
    value: "Juridik",
    label: "Juridik (Juristprogrammet / Affärsjuridik)",
  },
  {
    value:
      "Konstrelaterade ämnen (måleri, skulptörer, författare, tecknare, scenisk gestaltning, grafiker, film, bildkonst, fotografi, illustration, med mera)",
    label:
      "Konstrelaterade ämnen (måleri, skulptörer, författare, tecknare, scenisk gestaltning, grafiker, film, bildkonst, fotografi, illustration, med mera)",
  },
  {
    value: "Litteratur & Språkkunskap",
    label: "Litteratur / Språkkunskap / Konstvetenskap / Kulturvetenskap / Bibliotekarie",
  },
  { value: "Läkare", label: "Läkare" },
  { value: "Lärare & Utbildning", label: "Lärare & Utbildning" },
  {
    value: "Medicin, Vård & Omsorg",
    label:
      "Medicin, Vård, Omsorg & Hälsa (Läkare / Sjuksköterska / Ambulanssjukvårdare / Audionom / Barnmorska / Folkhälsovetenskap / Fysioterapeut / Global hälsa / Kiropraktor / Naprapat / Osteopat / Logoped / Medicinsk sekreterare / Omvårdnad / Optiker / Personlig assistent / Psykiatri / Tandhygienist / Tandläkare / Tandsköterska / Undersköterska / Vårdadministratör / Vårdbiträde)",
  },
  { value: "Musik", label: "Musik" },
  {
    value: "Politik & Samhällsrelaterade ämnen",
    label:
      "Politik / Internationella relationer/ Fred- och utvecklingsstudier / Geografi / Samhällsvetare / Statistik / Statsvetenskap / Samhällsplanering",
  },
  { value: "Psykologi", label: "Psykologi" },
  {
    value: "Sjuksköterska",
    label:
      "Sjuksköterska (Sjuksköterska / Undersköterska / Röntgensjuksköterska / Specialistsjuksköterska / Specialistundersköterska)",
  },
  { value: "Sjöfart", label: "Sjöfart" },
  { value: "Sport & Idrott", label: "Sport & Idrott" },
  {
    value:
      "Teknik & Natur (IT, Ingenjör, naturvetenskapliga ämnen såsom biologi, kemi, fysik, mm.) ",
    label:
      "Teknik & Natur (IT, Ingenjör, Biologi, Fysik, Geovetenskap, Kemi, Matematik, Miljö, Fordonsteknik, Datateknik, Industri och produktion, ljudteknik, mediateknik)",
  },
  { value: "Teologi/Religionsvetenskap", label: "Teologi/Religionsvetenskap" },
  { value: "Textil & Mode", label: "Textil & Mode" },
];

export const universities = [
  { value: "Beckmans designhögskola", label: "Beckmans designhögskola" },
  { value: "Blekinge tekniska högskola", label: "Blekinge tekniska högskola" },
  { value: "Chalmers tekniska högskola", label: "Chalmers tekniska högskola" },
  { value: "Enskilda Högskolan Stockholm", label: "Enskilda Högskolan Stockholm" },
  { value: "Ericastiftelsen", label: "Ericastiftelsen" },
  { value: "Ersta Sköndal Bräcke högskola", label: "Ersta Sköndal Bräcke högskola" },
  {
    value: "Förbundet S:t Lukas utbildningsinstitut",
    label: "Förbundet S:t Lukas utbildningsinstitut",
  },
  { value: "Försvarshögskolan", label: "Försvarshögskolan" },
  { value: "Gammelkroppa skogsskola", label: "Gammelkroppa skogsskola" },
  { value: "Gymnastik- och idrottshögskolan", label: "Gymnastik- och idrottshögskolan" },
  { value: "Göteborgs universitet", label: "Göteborgs universitet" },
  { value: "Handelshögskolan i Stockholm", label: "Handelshögskolan i Stockholm" },
  { value: "Högskolan Dalarna", label: "Högskolan Dalarna" },
  { value: "Högskolan Evidens", label: "Högskolan Evidens" },
  { value: "Högskolan Kristianstad", label: "Högskolan Kristianstad" },
  { value: "Högskolan Kristianstad", label: "Högskolan Kristianstad" },
  { value: "Högskolan Väst", label: "Högskolan Väst" },
  { value: "Högskolan Väst", label: "Högskolan Väst" },
  { value: "Högskolan i Borås", label: "Högskolan i Borås" },
  { value: "Högskolan i Gävle", label: "Högskolan i Gävle" },
  { value: "Högskolan i Halmstad", label: "Högskolan i Halmstad" },
  { value: "Högskolan i Halmstad", label: "Högskolan i Halmstad" },
  {
    value: "Högskolan i Jönköping/Jönköping University",
    label: "Högskolan i Jönköping/Jönköping University",
  },
  { value: "Högskolan i Skövde", label: "Högskolan i Skövde" },
  { value: "Högskolan i Skövde", label: "Högskolan i Skövde" },
  { value: "Karlstads universitet", label: "Karlstads universitet" },
  { value: "Karolinska institutet", label: "Karolinska institutet" },
  { value: "Konstfack", label: "Konstfack" },
  {
    value: "Kungl. Musikhögskolan i Stockholm",
    label: "Kungl. Musikhögskolan i Stockholm",
  },
  { value: "Kungliga Konsthögskolan", label: "Kungliga Konsthögskolan" },
  { value: "Kungliga Tekniska högskolan", label: "Kungliga Tekniska högskolan" },
  { value: "Linköpings universitet", label: "Linköpings universitet" },
  { value: "Linnéuniversitetet", label: "Linnéuniversitetet" },
  { value: "Luleå tekniska universitet", label: "Luleå tekniska universitet" },
  { value: "Lunds universitet", label: "Lunds universitet" },
  { value: "Malmö universitet", label: "Malmö universitet" },
  { value: "Mittuniversitetet", label: "Mittuniversitetet" },
  { value: "Mälardalens universitet", label: "Mälardalens universitet" },
  { value: "Newmaninstitutet", label: "Newmaninstitutet" },
  {
    value: "Nordiska högskolan för folkhälsovetenskap",
    label: "Nordiska högskolan för folkhälsovetenskap",
  },
  { value: "Röda korsets högskola", label: "Röda korsets högskola" },
  {
    value: "Skandinaviens akademi för psykoterapiutbildning",
    label: "Skandinaviens akademi för psykoterapiutbildning",
  },
  { value: "Sophiahemmet Högskola", label: "Sophiahemmet Högskola" },
  { value: "Sophiahemmet Högskola", label: "Sophiahemmet Högskola" },
  {
    value: "Stockholms Musikpedagogiska Institut",
    label: "Stockholms Musikpedagogiska Institut",
  },
  {
    value: "Stockholms konstnärliga högskola",
    label: "Stockholms konstnärliga högskola",
  },
  { value: "Stockholms universitet", label: "Stockholms universitet" },
  {
    value: "Svenska Institutet för kognitiv psykoterapi",
    label: "Svenska Institutet för kognitiv psykoterapi",
  },
  { value: "Sveriges lantbruksuniversitet", label: "Sveriges lantbruksuniversitet" },
  { value: "Södertörns högskola", label: "Södertörns högskola" },
  { value: "Umeå universitet", label: "Umeå universitet" },
  { value: "Uppsala universitet", label: "Uppsala universitet" },
  { value: "World Maritime University", label: "World Maritime University" },
  { value: "Örebro universitet", label: "Örebro universitet" },
];

export const lan = [
  { value: "Blekinge län", label: "Blekinge län" },
  { value: "Dalarnas län", label: "Dalarnas län" },
  { value: "Gotlands län", label: "Gotlands län" },
  { value: "Gävleborgs län", label: "Gävleborgs län" },
  { value: "Hallands län", label: "Hallands län" },
  { value: "Jämtlands län", label: "Jämtlands län" },
  { value: "Jönköpings län", label: "Jönköpings län" },
  { value: "Kalmar län", label: "Kalmar län" },
  { value: "Kronobergs län", label: "Kronobergs län" },
  { value: "Norrbottens län", label: "Norrbottens län" },
  { value: "Skåne län", label: "Skåne län" },
  { value: "Stockholms län", label: "Stockholms län" },
  { value: "Södermanlands län", label: "Södermanlands län" },
  { value: "Uppsala län", label: "Uppsala län" },
  { value: "Värmlands län", label: "Värmlands län" },
  { value: "Västerbottens län", label: "Västerbottens län" },
  { value: "Västernorrlands län", label: "Västernorrlands län" },
  { value: "Västmanlands län", label: "Västmanlands län" },
  { value: "Västra Götalands län", label: "Västra Götalands län" },
  { value: "Örebro län", label: "Örebro län" },
  { value: "Östergötlands län", label: "Östergötlands län" },
];

export const kommuner = [
  {
    value: "Annat/Inte applicerbart",
    label: "Annat/Inte applicerbart",
  },
  {
    value: "",
    label: "--- Alternativ ---",
    disabled: true,
  },
  { value: "Ale kommun", label: "Ale kommun" },
  { value: "Alingsås kommun", label: "Alingsås kommun" },
  { value: "Alvesta kommun", label: "Alvesta kommun" },
  { value: "Aneby kommun", label: "Aneby kommun" },
  { value: "Arboga kommun", label: "Arboga kommun" },
  { value: "Arjeplog kommun", label: "Arjeplog kommun" },
  { value: "Arvidsjaur kommun", label: "Arvidsjaur kommun" },
  { value: "Arvika kommun", label: "Arvika kommun" },
  { value: "Askersund kommun", label: "Askersund kommun" },
  { value: "Avesta kommun", label: "Avesta kommun" },
  { value: "Bengtsfors kommun", label: "Bengtsfors kommun" },
  { value: "Berg kommun", label: "Berg kommun" },
  { value: "Bjurholm kommun", label: "Bjurholm kommun" },
  { value: "Bjuv kommun", label: "Bjuv kommun" },
  { value: "Boden kommun", label: "Boden kommun" },
  { value: "Bollebygd kommun", label: "Bollebygd kommun" },
  { value: "Bollnäs kommun", label: "Bollnäs kommun" },
  { value: "Borgholm kommun", label: "Borgholm kommun" },
  { value: "Borlänge kommun", label: "Borlänge kommun" },
  { value: "Borås kommun", label: "Borås kommun" },
  { value: "Botkyrka kommun", label: "Botkyrka kommun" },
  { value: "Boxholm kommun", label: "Boxholm kommun" },
  { value: "Bromölla kommun", label: "Bromölla kommun" },
  { value: "Bräcke kommun", label: "Bräcke kommun" },
  { value: "Burlöv kommun", label: "Burlöv kommun" },
  { value: "Båstad kommun", label: "Båstad kommun" },
  { value: "Dals-Ed kommun", label: "Dals-Ed kommun" },
  { value: "Danderyd kommun", label: "Danderyd kommun" },
  { value: "Degerfors kommun", label: "Degerfors kommun" },
  { value: "Dorotea kommun", label: "Dorotea kommun" },
  { value: "Eda kommun", label: "Eda kommun" },
  { value: "Ekerö kommun", label: "Ekerö kommun" },
  { value: "Eksjö kommun", label: "Eksjö kommun" },
  { value: "Emmaboda kommun", label: "Emmaboda kommun" },
  { value: "Enköping kommun", label: "Enköping kommun" },
  { value: "Eskilstuna kommun", label: "Eskilstuna kommun" },
  { value: "Eslöv kommun", label: "Eslöv kommun" },
  { value: "Essunga kommun", label: "Essunga kommun" },
  { value: "Fagersta kommun", label: "Fagersta kommun" },
  { value: "Falkenberg kommun", label: "Falkenberg kommun" },
  { value: "Falköping kommun", label: "Falköping kommun" },
  { value: "Falun kommun", label: "Falun kommun" },
  { value: "Filipstad kommun", label: "Filipstad kommun" },
  { value: "Finspång kommun", label: "Finspång kommun" },
  { value: "Flen kommun", label: "Flen kommun" },
  { value: "Forshaga kommun", label: "Forshaga kommun" },
  { value: "Färgelanda kommun", label: "Färgelanda kommun" },
  { value: "Gagnef kommun", label: "Gagnef kommun" },
  { value: "Gislaved kommun", label: "Gislaved kommun" },
  { value: "Gnesta kommun", label: "Gnesta kommun" },
  { value: "Gnosjö kommun", label: "Gnosjö kommun" },
  { value: "Gotland kommun", label: "Gotland kommun" },
  { value: "Grums kommun", label: "Grums kommun" },
  { value: "Grästorp kommun", label: "Grästorp kommun" },
  { value: "Gullspång kommun", label: "Gullspång kommun" },
  { value: "Gällivare kommun", label: "Gällivare kommun" },
  { value: "Gävle kommun", label: "Gävle kommun" },
  { value: "Göteborg kommun", label: "Göteborg kommun" },
  { value: "Götene kommun", label: "Götene kommun" },
  { value: "Habo kommun", label: "Habo kommun" },
  { value: "Hagfors kommun", label: "Hagfors kommun" },
  { value: "Hallsberg kommun", label: "Hallsberg kommun" },
  { value: "Hallstahammar kommun", label: "Hallstahammar kommun" },
  { value: "Halmstad kommun", label: "Halmstad kommun" },
  { value: "Hammarö kommun", label: "Hammarö kommun" },
  { value: "Haninge kommun", label: "Haninge kommun" },
  { value: "Haparanda kommun", label: "Haparanda kommun" },
  { value: "Heby kommun", label: "Heby kommun" },
  { value: "Hedemora kommun", label: "Hedemora kommun" },
  { value: "Helsingborg kommun", label: "Helsingborg kommun" },
  { value: "Herrljunga kommun", label: "Herrljunga kommun" },
  { value: "Hjo kommun", label: "Hjo kommun" },
  { value: "Hofors kommun", label: "Hofors kommun" },
  { value: "Huddinge kommun", label: "Huddinge kommun" },
  { value: "Hudiksvall kommun", label: "Hudiksvall kommun" },
  { value: "Hultsfred kommun", label: "Hultsfred kommun" },
  { value: "Hylte kommun", label: "Hylte kommun" },
  { value: "Hällefors kommun", label: "Hällefors kommun" },
  { value: "Härjedalen kommun", label: "Härjedalen kommun" },
  { value: "Härnösand kommun", label: "Härnösand kommun" },
  { value: "Härryda kommun", label: "Härryda kommun" },
  { value: "Hässleholm kommun", label: "Hässleholm kommun" },
  { value: "Håbo kommun", label: "Håbo kommun" },
  { value: "Höganäs kommun", label: "Höganäs kommun" },
  { value: "Högsby kommun", label: "Högsby kommun" },
  { value: "Hörby kommun", label: "Hörby kommun" },
  { value: "Höör kommun", label: "Höör kommun" },
  { value: "Jokkmokk kommun", label: "Jokkmokk kommun" },
  { value: "Järfälla kommun", label: "Järfälla kommun" },
  { value: "Jönköping kommun", label: "Jönköping kommun" },
  { value: "Kalix kommun", label: "Kalix kommun" },
  { value: "Kalmar kommun", label: "Kalmar kommun" },
  { value: "Karlsborg kommun", label: "Karlsborg kommun" },
  { value: "Karlshamn kommun", label: "Karlshamn kommun" },
  { value: "Karlskoga kommun", label: "Karlskoga kommun" },
  { value: "Karlskrona kommun", label: "Karlskrona kommun" },
  { value: "Karlstad kommun", label: "Karlstad kommun" },
  { value: "Katrineholm kommun", label: "Katrineholm kommun" },
  { value: "Kil kommun", label: "Kil kommun" },
  { value: "Kinda kommun", label: "Kinda kommun" },
  { value: "Kiruna kommun", label: "Kiruna kommun" },
  { value: "Klippan kommun", label: "Klippan kommun" },
  { value: "Knivsta kommun", label: "Knivsta kommun" },
  { value: "Kramfors kommun", label: "Kramfors kommun" },
  { value: "Kristianstad kommun", label: "Kristianstad kommun" },
  { value: "Kristinehamn kommun", label: "Kristinehamn kommun" },
  { value: "Krokom kommun", label: "Krokom kommun" },
  { value: "Kumla kommun", label: "Kumla kommun" },
  { value: "Kungsbacka kommun", label: "Kungsbacka kommun" },
  { value: "Kungsör kommun", label: "Kungsör kommun" },
  { value: "Kungälv kommun", label: "Kungälv kommun" },
  { value: "Kävlinge kommun", label: "Kävlinge kommun" },
  { value: "Köping kommun", label: "Köping kommun" },
  { value: "Laholm kommun", label: "Laholm kommun" },
  { value: "Landskrona kommun", label: "Landskrona kommun" },
  { value: "Laxå kommun", label: "Laxå kommun" },
  { value: "Lekeberg kommun", label: "Lekeberg kommun" },
  { value: "Leksand kommun", label: "Leksand kommun" },
  { value: "Lerum kommun", label: "Lerum kommun" },
  { value: "Lessebo kommun", label: "Lessebo kommun" },
  { value: "Lidingö kommun", label: "Lidingö kommun" },
  { value: "Lidköping kommun", label: "Lidköping kommun" },
  { value: "Lilla Edet kommun", label: "Lilla Edet kommun" },
  { value: "Lindesberg kommun", label: "Lindesberg kommun" },
  { value: "Linköping kommun", label: "Linköping kommun" },
  { value: "Ljungby kommun", label: "Ljungby kommun" },
  { value: "Ljusdal kommun", label: "Ljusdal kommun" },
  { value: "Ljusnarsberg kommun", label: "Ljusnarsberg kommun" },
  { value: "Lomma kommun", label: "Lomma kommun" },
  { value: "Ludvika kommun", label: "Ludvika kommun" },
  { value: "Luleå kommun", label: "Luleå kommun" },
  { value: "Lund kommun", label: "Lund kommun" },
  { value: "Lycksele kommun", label: "Lycksele kommun" },
  { value: "Lysekil kommun", label: "Lysekil kommun" },
  { value: "Malmö kommun", label: "Malmö kommun" },
  { value: "Malung-Sälen kommun", label: "Malung-Sälen kommun" },
  { value: "Malå kommun", label: "Malå kommun" },
  { value: "Mariestad kommun", label: "Mariestad kommun" },
  { value: "Mark kommun", label: "Mark kommun" },
  { value: "Markaryd kommun", label: "Markaryd kommun" },
  { value: "Mellerud kommun", label: "Mellerud kommun" },
  { value: "Mjölby kommun", label: "Mjölby kommun" },
  { value: "Mora kommun", label: "Mora kommun" },
  { value: "Motala kommun", label: "Motala kommun" },
  { value: "Mullsjö kommun", label: "Mullsjö kommun" },
  { value: "Munkedal kommun", label: "Munkedal kommun" },
  { value: "Munkfors kommun", label: "Munkfors kommun" },
  { value: "Mölndal kommun", label: "Mölndal kommun" },
  { value: "Mönsterås kommun", label: "Mönsterås kommun" },
  { value: "Mörbylånga kommun", label: "Mörbylånga kommun" },
  { value: "Nacka kommun", label: "Nacka kommun" },
  { value: "Nora kommun", label: "Nora kommun" },
  { value: "Norberg kommun", label: "Norberg kommun" },
  { value: "Nordanstig kommun", label: "Nordanstig kommun" },
  { value: "Nordmaling kommun", label: "Nordmaling kommun" },
  { value: "Norrköping kommun", label: "Norrköping kommun" },
  { value: "Norrtälje kommun", label: "Norrtälje kommun" },
  { value: "Norsjö kommun", label: "Norsjö kommun" },
  { value: "Nybro kommun", label: "Nybro kommun" },
  { value: "Nykvarn kommun", label: "Nykvarn kommun" },
  { value: "Nyköping kommun", label: "Nyköping kommun" },
  { value: "Nynäshamn kommun", label: "Nynäshamn kommun" },
  { value: "Nässjö kommun", label: "Nässjö kommun" },
  { value: "Ockelbo kommun", label: "Ockelbo kommun" },
  { value: "Olofström kommun", label: "Olofström kommun" },
  { value: "Orsa kommun", label: "Orsa kommun" },
  { value: "Orust kommun", label: "Orust kommun" },
  { value: "Osby kommun", label: "Osby kommun" },
  { value: "Oskarshamn kommun", label: "Oskarshamn kommun" },
  { value: "Ovanåker kommun", label: "Ovanåker kommun" },
  { value: "Oxelösund kommun", label: "Oxelösund kommun" },
  { value: "Pajala kommun", label: "Pajala kommun" },
  { value: "Partille kommun", label: "Partille kommun" },
  { value: "Perstorp kommun", label: "Perstorp kommun" },
  { value: "Piteå kommun", label: "Piteå kommun" },
  { value: "Ragunda kommun", label: "Ragunda kommun" },
  { value: "Robertsfors kommun", label: "Robertsfors kommun" },
  { value: "Ronneby kommun", label: "Ronneby kommun" },
  { value: "Rättvik kommun", label: "Rättvik kommun" },
  { value: "Sala kommun", label: "Sala kommun" },
  { value: "Salem kommun", label: "Salem kommun" },
  { value: "Sandviken kommun", label: "Sandviken kommun" },
  { value: "Sigtuna kommun", label: "Sigtuna kommun" },
  { value: "Simrishamn kommun", label: "Simrishamn kommun" },
  { value: "Sjöbo kommun", label: "Sjöbo kommun" },
  { value: "Skara kommun", label: "Skara kommun" },
  { value: "Skellefteå kommun", label: "Skellefteå kommun" },
  { value: "Skinnskatteberg kommun", label: "Skinnskatteberg kommun" },
  { value: "Skurup kommun", label: "Skurup kommun" },
  { value: "Skövde kommun", label: "Skövde kommun" },
  { value: "Smedjebacken kommun", label: "Smedjebacken kommun" },
  { value: "Sollefteå kommun", label: "Sollefteå kommun" },
  { value: "Sollentuna kommun", label: "Sollentuna kommun" },
  { value: "Solna kommun", label: "Solna kommun" },
  { value: "Sorsele kommun", label: "Sorsele kommun" },
  { value: "Sotenäs kommun", label: "Sotenäs kommun" },
  { value: "Staffanstorp kommun", label: "Staffanstorp kommun" },
  { value: "Stenungsund kommun", label: "Stenungsund kommun" },
  { value: "Stockholm kommun", label: "Stockholm kommun" },
  { value: "Storfors kommun", label: "Storfors kommun" },
  { value: "Storuman kommun", label: "Storuman kommun" },
  { value: "Strängnäs kommun", label: "Strängnäs kommun" },
  { value: "Strömstad kommun", label: "Strömstad kommun" },
  { value: "Strömsund kommun", label: "Strömsund kommun" },
  { value: "Sundbyberg kommun", label: "Sundbyberg kommun" },
  { value: "Sundsvall kommun", label: "Sundsvall kommun" },
  { value: "Sunne kommun", label: "Sunne kommun" },
  { value: "Surahammar kommun", label: "Surahammar kommun" },
  { value: "Svalöv kommun", label: "Svalöv kommun" },
  { value: "Svedala kommun", label: "Svedala kommun" },
  { value: "Svenljunga kommun", label: "Svenljunga kommun" },
  { value: "Säffle kommun", label: "Säffle kommun" },
  { value: "Säter kommun", label: "Säter kommun" },
  { value: "Sävsjö kommun", label: "Sävsjö kommun" },
  { value: "Söderhamn kommun", label: "Söderhamn kommun" },
  { value: "Söderköping kommun", label: "Söderköping kommun" },
  { value: "Södertälje kommun", label: "Södertälje kommun" },
  { value: "Sölvesborg kommun", label: "Sölvesborg kommun" },
  { value: "Tanum kommun", label: "Tanum kommun" },
  { value: "Tibro kommun", label: "Tibro kommun" },
  { value: "Tidaholm kommun", label: "Tidaholm kommun" },
  { value: "Tierp kommun", label: "Tierp kommun" },
  { value: "Timrå kommun", label: "Timrå kommun" },
  { value: "Tingsryd kommun", label: "Tingsryd kommun" },
  { value: "Tjörn kommun", label: "Tjörn kommun" },
  { value: "Tomelilla kommun", label: "Tomelilla kommun" },
  { value: "Torsby kommun", label: "Torsby kommun" },
  { value: "Torsås kommun", label: "Torsås kommun" },
  { value: "Tranemo kommun", label: "Tranemo kommun" },
  { value: "Tranås kommun", label: "Tranås kommun" },
  { value: "Trelleborg kommun", label: "Trelleborg kommun" },
  { value: "Trollhättan kommun", label: "Trollhättan kommun" },
  { value: "Trosa kommun", label: "Trosa kommun" },
  { value: "Tyresö kommun", label: "Tyresö kommun" },
  { value: "Täby kommun", label: "Täby kommun" },
  { value: "Töreboda kommun", label: "Töreboda kommun" },
  { value: "Uddevalla kommun", label: "Uddevalla kommun" },
  { value: "Ulricehamn kommun", label: "Ulricehamn kommun" },
  { value: "Umeå kommun", label: "Umeå kommun" },
  { value: "Upplands Väsby kommun", label: "Upplands Väsby kommun" },
  { value: "Upplands-Bro kommun", label: "Upplands-Bro kommun" },
  { value: "Uppsala kommun", label: "Uppsala kommun" },
  { value: "Uppvidinge kommun", label: "Uppvidinge kommun" },
  { value: "Vadstena kommun", label: "Vadstena kommun" },
  { value: "Vaggeryd kommun", label: "Vaggeryd kommun" },
  { value: "Valdemarsvik kommun", label: "Valdemarsvik kommun" },
  { value: "Vallentuna kommun", label: "Vallentuna kommun" },
  { value: "Vansbro kommun", label: "Vansbro kommun" },
  { value: "Vara kommun", label: "Vara kommun" },
  { value: "Varberg kommun", label: "Varberg kommun" },
  { value: "Vaxholm kommun", label: "Vaxholm kommun" },
  { value: "Vellinge kommun", label: "Vellinge kommun" },
  { value: "Vetlanda kommun", label: "Vetlanda kommun" },
  { value: "Vilhelmina kommun", label: "Vilhelmina kommun" },
  { value: "Vimmerby kommun", label: "Vimmerby kommun" },
  { value: "Vindeln kommun", label: "Vindeln kommun" },
  { value: "Vingåker kommun", label: "Vingåker kommun" },
  { value: "Vänersborg kommun", label: "Vänersborg kommun" },
  { value: "Vännäs kommun", label: "Vännäs kommun" },
  { value: "Värmdö kommun", label: "Värmdö kommun" },
  { value: "Värnamo kommun", label: "Värnamo kommun" },
  { value: "Västervik kommun", label: "Västervik kommun" },
  { value: "Västerås kommun", label: "Västerås kommun" },
  { value: "Växjö kommun", label: "Växjö kommun" },
  { value: "Vårgårda kommun", label: "Vårgårda kommun" },
  { value: "Ydre kommun", label: "Ydre kommun" },
  { value: "Ystad kommun", label: "Ystad kommun" },
  { value: "Älmhult kommun", label: "Älmhult kommun" },
  { value: "Älvdalen kommun", label: "Älvdalen kommun" },
  { value: "Älvkarleby kommun", label: "Älvkarleby kommun" },
  { value: "Älvsbyn kommun", label: "Älvsbyn kommun" },
  { value: "Ängelholm kommun", label: "Ängelholm kommun" },
  { value: "Åmål kommun", label: "Åmål kommun" },
  { value: "Ånge kommun", label: "Ånge kommun" },
  { value: "Åre kommun", label: "Åre kommun" },
  { value: "Årjäng kommun", label: "Årjäng kommun" },
  { value: "Åsele kommun", label: "Åsele kommun" },
  { value: "Åstorp kommun", label: "Åstorp kommun" },
  { value: "Åtvidaberg kommun", label: "Åtvidaberg kommun" },
  { value: "Öckerö kommun", label: "Öckerö kommun" },
  { value: "Ödeshög kommun", label: "Ödeshög kommun" },
  { value: "Örebro kommun", label: "Örebro kommun" },
  { value: "Örkelljunga kommun", label: "Örkelljunga kommun" },
  { value: "Örnsköldsvik kommun", label: "Örnsköldsvik kommun" },
  { value: "Östersund kommun", label: "Östersund kommun" },
  { value: "Österåker kommun", label: "Österåker kommun" },
  { value: "Östhammar kommun", label: "Östhammar kommun" },
  { value: "Östra Göinge kommun", label: "Östra Göinge kommun" },
  { value: "Överkalix kommun", label: "Överkalix kommun" },
  { value: "Övertorneå kommun", label: "Övertorneå kommun" },
];

export const countries = [
  {
    value: "Annat/Inte applicerbart",
    label: "Annat/Inte applicerbart",
  },
  {
    value: "",
    label: "--- Alternativ ---",
    disabled: true,
  },
  { value: "Afghanistan", label: "Afghanistan" },
  { value: "Albanien", label: "Albanien" },
  { value: "Algeriet", label: "Algeriet" },
  { value: "Andorra", label: "Andorra" },
  { value: "Angola", label: "Angola" },
  { value: "Antigua och Barbuda", label: "Antigua och Barbuda" },
  { value: "Argentina", label: "Argentina" },
  { value: "Armenien", label: "Armenien" },
  { value: "Australien", label: "Australien" },
  { value: "Azerbajdzjan", label: "Azerbajdzjan" },
  { value: "Bahamas", label: "Bahamas" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "Barbados", label: "Barbados" },
  { value: "Belgien", label: "Belgien" },
  { value: "Belize", label: "Belize" },
  { value: "Benin", label: "Benin" },
  { value: "Bhutan", label: "Bhutan" },
  { value: "Bolivia", label: "Bolivia" },
  { value: "Bosnien och Hercegovina", label: "Bosnien och Hercegovina" },
  { value: "Botswana", label: "Botswana" },
  { value: "Brasilien", label: "Brasilien" },
  { value: "Brunei", label: "Brunei" },
  { value: "Bulgarien", label: "Bulgarien" },
  { value: "Burkina Faso", label: "Burkina Faso" },
  { value: "Burundi", label: "Burundi" },
  { value: "Centralafrikanska republiken", label: "Centralafrikanska republiken" },
  { value: "Chile", label: "Chile" },
  { value: "Colombia", label: "Colombia" },
  { value: "Comorerna", label: "Comorerna" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Cypern", label: "Cypern" },
  { value: "Danmark", label: "Danmark" },
  { value: "Demokratiska republiken Kongo", label: "Demokratiska republiken Kongo" },
  { value: "Djibouti", label: "Djibouti" },
  { value: "Dominica", label: "Dominica" },
  { value: "Dominikanska republiken", label: "Dominikanska republiken" },
  { value: "Ecuador", label: "Ecuador" },
  { value: "Egypten", label: "Egypten" },
  { value: "Ekvatorialguinea", label: "Ekvatorialguinea" },
  { value: "El Salvador", label: "El Salvador" },
  { value: "Elfenbenskusten", label: "Elfenbenskusten" },
  { value: "Eritrea", label: "Eritrea" },
  { value: "Estland", label: "Estland" },
  { value: "Etiopien", label: "Etiopien" },
  { value: "Fiji", label: "Fiji" },
  { value: "Filippinerna", label: "Filippinerna" },
  { value: "Finland", label: "Finland" },
  { value: "Frankrike", label: "Frankrike" },
  { value: "Förenade Arabemiraten", label: "Förenade Arabemiraten" },
  { value: "Gabon", label: "Gabon" },
  { value: "Gambia", label: "Gambia" },
  { value: "Georgien", label: "Georgien" },
  { value: "Ghana", label: "Ghana" },
  { value: "Grekland", label: "Grekland" },
  { value: "Grenada", label: "Grenada" },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Guinea", label: "Guinea" },
  { value: "Guinea - Bissau", label: "Guinea - Bissau" },
  { value: "Guyana", label: "Guyana" },
  { value: "Haiti", label: "Haiti" },
  { value: "Honduras", label: "Honduras" },
  { value: "Indien", label: "Indien" },
  { value: "Indonesien", label: "Indonesien" },
  { value: "Irak", label: "Irak" },
  { value: "Iran", label: "Iran" },
  { value: "Irland", label: "Irland" },
  { value: "Island", label: "Island" },
  { value: "Israel", label: "Israel" },
  { value: "Italien", label: "Italien" },
  { value: "Jamaica", label: "Jamaica" },
  { value: "Japan", label: "Japan" },
  { value: "Jemen", label: "Jemen" },
  { value: "Jordanien", label: "Jordanien" },
  { value: "Kambodja", label: "Kambodja" },
  { value: "Kamerun", label: "Kamerun" },
  { value: "Kanada", label: "Kanada" },
  { value: "Kap Verde", label: "Kap Verde" },
  { value: "Kazakstan", label: "Kazakstan" },
  { value: "Kenya", label: "Kenya" },
  { value: "Kina", label: "Kina" },
  { value: "Kirgizistan", label: "Kirgizistan" },
  { value: "Kiribati", label: "Kiribati" },
  { value: "Kongo (Republiken Kongo)", label: "Kongo (Republiken Kongo)" },
  { value: "Kosovo", label: "Kosovo" },
  { value: "Kroatien", label: "Kroatien" },
  { value: "Kuba", label: "Kuba" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Laos", label: "Laos" },
  { value: "Lesotho", label: "Lesotho" },
  { value: "Lettland", label: "Lettland" },
  { value: "Libanon", label: "Libanon" },
  { value: "Liberia", label: "Liberia" },
  { value: "Libyen", label: "Libyen" },
  { value: "Liechtenstein", label: "Liechtenstein" },
  { value: "Litauen", label: "Litauen" },
  { value: "Luxemburg", label: "Luxemburg" },
  { value: "Madagaskar", label: "Madagaskar" },
  { value: "Makedonien", label: "Makedonien" },
  { value: "Malawi", label: "Malawi" },
  { value: "Malaysia", label: "Malaysia" },
  { value: "Maldiverna", label: "Maldiverna" },
  { value: "Mali", label: "Mali" },
  { value: "Malta", label: "Malta" },
  { value: "Marocko", label: "Marocko" },
  { value: "Marshallöarna", label: "Marshallöarna" },
  { value: "Mauretanien", label: "Mauretanien" },
  { value: "Mauritius", label: "Mauritius" },
  { value: "Mexiko", label: "Mexiko" },
  { value: "Mikronesien", label: "Mikronesien" },
  { value: "Moçambique", label: "Moçambique" },
  { value: "Moldavien", label: "Moldavien" },
  { value: "Monaco", label: "Monaco" },
  { value: "Mongoliet", label: "Mongoliet" },
  { value: "Montenegro", label: "Montenegro" },
  { value: "Myanmar", label: "Myanmar" },
  { value: "Namibia", label: "Namibia" },
  { value: "Nauru", label: "Nauru" },
  { value: "Nederländerna", label: "Nederländerna" },
  { value: "Nepal", label: "Nepal" },
  { value: "Nicaragua", label: "Nicaragua" },
  { value: "Niger", label: "Niger" },
  { value: "Nigeria", label: "Nigeria" },
  { value: "Nordkorea", label: "Nordkorea" },
  { value: "Norge", label: "Norge" },
  { value: "Nya Zeeland", label: "Nya Zeeland" },
  { value: "Oman", label: "Oman" },
  { value: "Pakistan", label: "Pakistan" },
  { value: "Palau", label: "Palau" },
  { value: "Palestina", label: "Palestina" },
  { value: "Panama", label: "Panama" },
  { value: "Papua Nya Guinea", label: "Papua Nya Guinea" },
  { value: "Paraguay", label: "Paraguay" },
  { value: "Peru", label: "Peru" },
  { value: "Polen", label: "Polen" },
  { value: "Portugal", label: "Portugal" },
  { value: "Qatar", label: "Qatar" },
  { value: "Rumänien", label: "Rumänien" },
  { value: "Rwanda", label: "Rwanda" },
  { value: "Ryssland", label: "Ryssland" },
  { value: "Saint Kitts och Nevis", label: "Saint Kitts och Nevis" },
  { value: "Saint Lucia", label: "Saint Lucia" },
  { value: "Saint Vincent och Grenadinerna", label: "Saint Vincent och Grenadinerna" },
  { value: "Salomonöarna", label: "Salomonöarna" },
  { value: "Samoa", label: "Samoa" },
  { value: "San Marino", label: "San Marino" },
  { value: "São Tomé och Principe", label: "São Tomé och Principe" },
  { value: "Saudiarabien", label: "Saudiarabien" },
  { value: "Schweiz", label: "Schweiz" },
  { value: "Senegal", label: "Senegal" },
  { value: "Serbien", label: "Serbien" },
  { value: "Seychellerna", label: "Seychellerna" },
  { value: "Sierra Leone", label: "Sierra Leone" },
  { value: "Singapore", label: "Singapore" },
  { value: "Slovakien", label: "Slovakien" },
  { value: "Slovenien", label: "Slovenien" },
  { value: "Somalia", label: "Somalia" },
  { value: "Spanien", label: "Spanien" },
  { value: "Sri Lanka", label: "Sri Lanka" },
  { value: "Storbritannien", label: "Storbritannien" },
  { value: "Sudan", label: "Sudan" },
  { value: "Surinam", label: "Surinam" },
  { value: "Swaziland", label: "Swaziland" },
  { value: "Sverige", label: "Sverige" },
  { value: "Sydafrika", label: "Sydafrika" },
  { value: "Sydkorea", label: "Sydkorea" },
  { value: "Sydsudan", label: "Sydsudan" },
  { value: "Syrien", label: "Syrien" },
  { value: "Tadzjikistan", label: "Tadzjikistan" },
  { value: "Taiwan", label: "Taiwan" },
  { value: "Tanzania", label: "Tanzania" },
  { value: "Tchad", label: "Tchad" },
  { value: "Thailand", label: "Thailand" },
  { value: "Tjeckien", label: "Tjeckien" },
  { value: "Togo", label: "Togo" },
  { value: "Tonga", label: "Tonga" },
  { value: "Trinidad och Tobago", label: "Trinidad och Tobago" },
  { value: "Tunisien", label: "Tunisien" },
  { value: "Turkiet", label: "Turkiet" },
  { value: "Turkmenistan", label: "Turkmenistan" },
  { value: "Tuvalu", label: "Tuvalu" },
  { value: "Tyskland", label: "Tyskland" },
  { value: "Uganda", label: "Uganda" },
  { value: "Ukraina", label: "Ukraina" },
  { value: "Ungern", label: "Ungern" },
  { value: "Uruguay", label: "Uruguay" },
  { value: "USA", label: "USA" },
  { value: "Uzbekistan", label: "Uzbekistan" },
  { value: "Vanuatu", label: "Vanuatu" },
  { value: "Vatikanstaten", label: "Vatikanstaten" },
  { value: "Venezuela", label: "Venezuela" },
  { value: "Vietnam", label: "Vietnam" },
  { value: "Vitryssland", label: "Vitryssland" },
  { value: "Zambia", label: "Zambia" },
  { value: "Zimbabwe", label: "Zimbabwe" },
  { value: "Österrike", label: "Österrike" },
  { value: "Östtimor", label: "Östtimor" },
];

export const funFacts = [
  [
    "Gillar du Stipendly? Rekommendera gärna ",
    <span className="red-text">Stipendly</span>,
    " till dina vänner och ge dem 10% rabatt med koden ",
    <b>STIPENDIEKOMPIS10</b>,
    " som kan användas vid betalning.",
  ],
  isMobile
    ? ["Visste du att Stipendly funkar på datorn också?"]
    : ["Visste du att Stipendly funkar på mobilen också?"],
  [
    "Många högskolor och universitet erbjuder stipendier till studenter som går på deras skola!",
  ],
  ["Att söka stipendier kan innebära pengar till dig för en bättre studietid!"],
];
