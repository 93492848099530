import React, { useRef, useState } from "react";
import { useAuth } from "../contexts/AuthContext.js";
import { Form, Button, Alert, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import pcandmobile from "../images/pcandmobile.png";
import { isMobile } from "react-device-detect";

export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [validated, setValidated] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      try {
        setError("");
        setLoading(true);
        await login(emailRef.current.value, passwordRef.current.value);
        setValidated(true);
        navigate("/");
      } catch (e) {
        if (e.code === "auth/user-not-found") {
          setError("Kontot kunde inte hittas.");
        }
        if (e.code === "auth/wrong-password") {
          setError("Fel lösenord.");
        }
      }
    }

    setValidated(true);
    setLoading(false);
  }
  return (
    <div
      className="Register"
      style={{
        minHeight: "90vh",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        position: "relative",
        flex: "1",
      }}
    >
      <div
        style={{
          width: isMobile ? "100%" : "40%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          style={{ maxWidth: "25rem", width: "75vw" }}
        >
          <div style={{ textAlign: "center", marginBottom:"3rem" }}>
            <h2 style={{fontSize: isMobile && "2rem"}}>
              <span className="red-text">Logga</span> in
            </h2>
          </div>
          {error && <Alert variant="danger">{error}</Alert>}

          <div class="form-outline mb-4">
            <Form.Group id="email" as={Col} md="20" controlId="validationCustom01">
              <Form.Label>E-post</Form.Label>
              <Form.Control required type="email" ref={emailRef} placeholder="E-post" />
              <Form.Control.Feedback type="invalid">
                Måste fyllas i.
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          <div class="form-outline mb-4">
            <Form.Group id="password" as={Col} md="20" controlId="validationCustom03">
              <Form.Label>Lösenord</Form.Label>
              <Form.Control
                type="password"
                ref={passwordRef}
                placeholder="Lösenord"
                required
              />
              <Form.Control.Feedback type="invalid">
                Måste fyllas i.
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          <div class="row mb-4">
            <div class="col">
              <Link to="/glomt-losenord">Glömt Lösenord?</Link>
            </div>
          </div>

          <Button
            disabled={loading}
            type="submit"
            variant="flat"
            class="btn btn-primary btn-block mb-3"
            className="button"
          >
            Logga in
          </Button>

          <div class="text-center" style={{ paddingTop: "2rem" }}>
            <p>
              Inget konto? <Link to="/skapa-konto">Registrera här</Link>
            </p>
          </div>
        </Form>
      </div>
      {!isMobile && (
        <img
          src={pcandmobile}
          width="40%"
          className="d-inline-block align-center"
          alt=""
        />
      )}
    </div>
  );
}
