import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row, ToggleButton } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { db } from "../firebase";
import { doc, updateDoc } from "firebase/firestore";
import "../App.css";
import { BlurStipendie } from "./Stipendie";
import { Bookmark, BoxArrowUpRight, CardList } from "react-bootstrap-icons";
import SiteLoad from "./SiteLoad";
import { format, tags } from "./HelperFunctions";
import { Link, useLocation } from "react-router-dom";
import InfoToast from "./InfoToast";

export default function Dashboard({ payed, scholarshipList, sum, showC, setShowC }) {
  const [liked, setLiked] = useState([]);
  const { currentUser, getLiked } = useAuth();
  const { state } = useLocation();

  useEffect(() => {
    async function getLike() {
      setLiked(await getLiked());
    }
    getLike();
  }, [getLiked]);

  // STIPENDIE ////////////////////////////

  const [updatedLikes, setUpdatedLikes] = useState(new Set());

  useEffect(() => {
    const userRef = doc(db, "users", currentUser.uid);

    async function updateLikes(updatedLikes) {
      try {
        await updateDoc(userRef, {
          liked: [...updatedLikes],
        });
      } catch (e) {}
    }
    return () => updateLikes(updatedLikes);
  }, [currentUser.uid, updatedLikes]);

  const Yoman = ({ id, updatedLikes }) => {
    const [checked, setChecked] = useState(false);

    useEffect(() => {
      if (liked.includes(id)) {
        setChecked(!checked);
        updatedLikes.add(id);
      }
      setUpdatedLikes(updatedLikes);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function toggleCheck() {
      setChecked(!checked);
      if (updatedLikes.has(id)) {
        updatedLikes.delete(id);
      } else {
        updatedLikes.add(id);
      }
      setUpdatedLikes(updatedLikes);
    }

    return (
      <ToggleButton
        className="bookmarkToggle"
        type="checkbox"
        variant="outline-light"
        checked={checked}
        value="1"
        onClick={toggleCheck}
        style={{
          fontSize: "18px",
          border: "none",
          margin: "none",
          padding: "none",
        }}
      >
        <Bookmark
          className={"innerBookmark"}
          style={{
            scale: "1.3",
          }}
        />
      </ToggleButton>
    );
  };

  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);

    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };

    return (
      <p className="text" style={{ marginBottom: "0px" }}>
        {isReadMore ? text.split(" ").slice(0, 30).join(" ") : text}
        <span onClick={toggleReadMore} className="read-or-hide">
          {text.split(" ").length >= 30 && isReadMore
            ? " ... Läs mer"
            : text.split(" ").length >= 30 && !isReadMore
            ? " Läs mindre"
            : ""}
        </span>
      </p>
    );
  };

  return (
    <>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <InfoToast
          show={showC}
          setShow={setShowC}
          from={"Dashboard"}
          icon={<CardList size={80} />}
          h1Text={
            payed === 0
              ? ["Mina", <span className="red-text"> Stipendier</span>]
              : payed === 1
              ? [
                  "Mina",
                  <span className="red-text"> Stipendier</span>,
                  <br />,
                  <br />,
                  <span className="red-text">{currentUser.displayName}</span>,
                  ", vi har matchat din profil ",
                  <br />, "med ",
                  <span className="red-text">{scholarshipList.length} stipendier</span>,
                  "!",
                ]
              : payed === 2
              ? [
                  "Mina",
                  <span className="red-text"> Stipendier</span>,
                  " (",
                  scholarshipList.length,
                  " st)",
                ]
              : []
          }
          pText={
            payed === 0
              ? [
                  "Här kommer dina matchade",
                  <span className="red-text"> Stipendier</span>,
                  " att visas men först måste du ",
                  <Link to={"/skapa-profil"}>skapa en profil</Link>,
                  "!",
                ]
              : payed === 1
              ? [
                  "Du kan se förhands-info om dina matchningar i listan här nedanför. Om du vill låsa upp din personliga lista på stipendier så måste du först betala. Det gör du via betalningsknappen högst upp på sidan!",
                ]
              : payed === 2 && state?.previousPath === "/b16de8f73f012819db17130988ce5d84"
              ? [
                  "Tack för ditt köp! Ett mejl med kvitto och information har skickats till din mejladress. ",
                  "Dina ",
                  <span className="red-text"> Stipendier </span>,
                  "är nu tillgängliga och hittas här nedanför!",
                ]
              : payed === 2
              ? [
                  "Dina ",
                  <span className="red-text"> Stipendier </span>,
                  "är nu tillgängliga och hittas här nedanför!",
                ]
              : []
          }
          payed={payed}
        />
      </div>

      {payed === 0 ? (
        <></>
      ) : scholarshipList.length === 0 ? (
        <SiteLoad />
      ) : payed === 1 ? (
        <>
          {scholarshipList.map((doc, index) => (
            index > 0 ? (
            <BlurStipendie
              doc={doc}
              selectedQ1={doc.data().Q1}
              selectedQ2={doc.data().Q2}
              selectedQ3={doc.data().Q3}
              selectedQ4={doc.data().Q4}
              selectedQ5={doc.data().Q5}
              selectedQ6={doc.data().Q6}
              selectedQ7={doc.data().Q7}
              selectedQ8={doc.data().Q8}
              selectedQ9={doc.data().Q9}
              selectedQ10={doc.data().Q10}
              selectedQ11={doc.data().Q11}
              selectedQ12={doc.data().Q12}
              selectedQ13={doc.data().Q13}
              selectedQ14={doc.data().Q14}
              selectedQ15={doc.data().Q15}
              selectedQ16={doc.data().Q16}
            />
            ) : (  
            <>
              <Container
                style={{
                  maxWidth: "50rem",
                  width: "98%",
                  marginBottom: "3rem",
                  marginTop: "0rem",
                }}
              >
                <Card
                  className="text-center shadow"
                  style={{ border: "2px solid", borderRadius: "8px" }}
                >
                  <Card.Header className="blue">
                    <Card.Title
                      key={doc.data().title}
                      style={{
                        width: "100%",
                        margin: "0px",
                      }}
                    >
                      <Container
                        style={{
                          margin: "0px",
                          padding: "0px",
                        }}
                      >
                        <Row>
                          <Col
                            md={11}
                            sm={10}
                            xs={10}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              textAlign: "left",
                            }}
                          >
                            {doc.data().title}
                          </Col>
                          <Col md={1} sm={2} xs={2}>
                            <Yoman
                              id={doc.id}
                              updatedLikes={updatedLikes}
                              key={index}
                            ></Yoman>
                          </Col>
                        </Row>
                      </Container>
                    </Card.Title>
                  </Card.Header>
                  {tags([
                    doc.data().Q1[0],
                    doc.data().Q2[0],
                    doc.data().Q3[0],
                    doc.data().Q4[0],
                    doc.data().Q5[0],
                    doc.data().Q6[0],
                    doc.data().Q7[0],
                    doc.data().Q8[0],
                    doc.data().Q9[0],
                    doc.data().Q10[0],
                    doc.data().Q11[0],
                    doc.data().Q12[0],
                    doc.data().Q13[0],
                    doc.data().Q14[0],
                    doc.data().Q15[0],
                  ]) && (
                    <Card.Header>
                      <Row style={{ textAlign: "left" }}>
                        <Col>
                          {tags([
                            doc.data().Q1[0],
                            doc.data().Q2[0],
                            doc.data().Q3[0],
                            doc.data().Q4[0],
                            doc.data().Q5[0],
                            doc.data().Q6[0],
                            doc.data().Q7[0],
                            doc.data().Q8[0],
                            doc.data().Q9[0],
                            doc.data().Q10[0],
                            doc.data().Q11[0],
                            doc.data().Q12[0],
                            doc.data().Q13[0],
                            doc.data().Q14[0],
                            doc.data().Q15[0],
                          ])}
                        </Col>
                      </Row>
                    </Card.Header>
                  )}
                  <Card.Body>
                    <Card.Text
                      style={{
                        paddingTop: "0.5vh",
                        paddingBottom: "0.5vh",
                        textAlign: "left",
                      }}
                    >
                      {" "}
                      <ReadMore>{doc.data().info}</ReadMore>
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer
                    style={{ backgroundColor: "#F8F8F8" }}
                    className="text-muted"
                  >
                    <Container>
                      <Row
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Col>
                          <span className="green-text">
                            Belopp: {format(doc.data().belopp)}
                          </span>
                        </Col>
                        <Col>
                          <a href={doc.data().url} target="_blank" rel="noreferrer">
                            <Button
                              variant="flat"
                              class="btn btn-primary btn-block"
                              className="button"
                            >
                              <BoxArrowUpRight /> Ansök här
                            </Button>
                          </a>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Footer>
                </Card>
              </Container>
            </>
            )
          ))}
        </>
      ) : payed === 2 ? (
        <>
          {scholarshipList.map((doc, index) => (
            <>
              <Container
                style={{
                  maxWidth: "50rem",
                  width: "98%",
                  marginBottom: "3rem",
                  marginTop: "0rem",
                }}
              >
                <Card
                  className="text-center shadow"
                  style={{ border: "2px solid", borderRadius: "8px" }}
                >
                  <Card.Header className="blue">
                    <Card.Title
                      key={doc.data().title}
                      style={{
                        width: "100%",
                        margin: "0px",
                      }}
                    >
                      <Container
                        style={{
                          margin: "0px",
                          padding: "0px",
                        }}
                      >
                        <Row>
                          <Col
                            md={11}
                            sm={10}
                            xs={10}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              textAlign: "left",
                            }}
                          >
                            {doc.data().title}
                          </Col>
                          <Col md={1} sm={2} xs={2}>
                            <Yoman
                              id={doc.id}
                              updatedLikes={updatedLikes}
                              key={index}
                            ></Yoman>
                          </Col>
                        </Row>
                      </Container>
                    </Card.Title>
                  </Card.Header>
                  {tags([
                    doc.data().Q1[0],
                    doc.data().Q2[0],
                    doc.data().Q3[0],
                    doc.data().Q4[0],
                    doc.data().Q5[0],
                    doc.data().Q6[0],
                    doc.data().Q7[0],
                    doc.data().Q8[0],
                    doc.data().Q9[0],
                    doc.data().Q10[0],
                    doc.data().Q11[0],
                    doc.data().Q12[0],
                    doc.data().Q13[0],
                    doc.data().Q14[0],
                    doc.data().Q15[0],
                  ]) && (
                    <Card.Header>
                      <Row style={{ textAlign: "left" }}>
                        <Col>
                          {tags([
                            doc.data().Q1[0],
                            doc.data().Q2[0],
                            doc.data().Q3[0],
                            doc.data().Q4[0],
                            doc.data().Q5[0],
                            doc.data().Q6[0],
                            doc.data().Q7[0],
                            doc.data().Q8[0],
                            doc.data().Q9[0],
                            doc.data().Q10[0],
                            doc.data().Q11[0],
                            doc.data().Q12[0],
                            doc.data().Q13[0],
                            doc.data().Q14[0],
                            doc.data().Q15[0],
                          ])}
                        </Col>
                      </Row>
                    </Card.Header>
                  )}
                  <Card.Body>
                    <Card.Text
                      style={{
                        paddingTop: "0.5vh",
                        paddingBottom: "0.5vh",
                        textAlign: "left",
                      }}
                    >
                      {" "}
                      <ReadMore>{doc.data().info}</ReadMore>
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer
                    style={{ backgroundColor: "#F8F8F8" }}
                    className="text-muted"
                  >
                    <Container>
                      <Row
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Col>
                          <span className="green-text">
                            Belopp: {format(doc.data().belopp)}
                          </span>
                        </Col>
                        <Col>
                          <a href={doc.data().url} target="_blank" rel="noreferrer">
                            <Button
                              variant="flat"
                              class="btn btn-primary btn-block"
                              className="button"
                            >
                              <BoxArrowUpRight /> Ansök här
                            </Button>
                          </a>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Footer>
                </Card>
              </Container>
            </>
          ))}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
