import React, { useState, useEffect } from "react";
import Register from "./Register";
import Dashboard from "./Dashboard";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import Start from "./Start";
import Settings from "./Settings";
import Password from "./Password";
import Liked from "./Liked";
import NonProfileRoute from "./NonProfileRoute";
import NotFound from "./NotFound";
import Payed from "./Payed";
import ToPayment from "./ToPayment";
import FooterBrowser from "./Footer.js";
import { FooterMobile } from "./Footer.js";
import { ArrowRightCircle } from "react-bootstrap-icons";
import { Container, Nav, Navbar, NavDropdown, Offcanvas } from "react-bootstrap";
import { AuthProvider } from "../contexts/AuthContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import LoggedInRoute from "./LoggedInRoute";
import UpdateProfile from "./UpdateProfile";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";
import omdome from "../images/betyg.svg";
import "../App.css";
import {
  BrowserView,
  isBrowser,
  isDesktop,
  isTablet,
  MobileView,
} from "react-device-detect";
import { isMobile } from "react-device-detect";

import { db } from "../firebase";
import { documentId, getDocs, updateDoc } from "firebase/firestore";
import { collection, query, where } from "firebase/firestore";
import { doc, getDoc } from "firebase/firestore";

import {
  ArrowRight,
  Bookmarks,
  CardList,
  CircleFill,
  FilePerson,
  Gear,
  House,
  InfoCircle,
  JournalRichtext,
  Key,
  List,
  People,
  PersonLinesFill,
  QuestionCircle,
  SquareFill,
} from "react-bootstrap-icons";
import Loading from "./Loading";
import SiteLoad from "./SiteLoad";
import { getAuth, onAuthStateChanged } from "firebase/auth";

function App() {
  const [currentUser, setCurrentUser] = useState();
  const [siteLoad, setSiteLoad] = useState(true);
  const [align, setAlign] = useState("begin");
  const [UserIsSet, setUserIsSet] = useState(false);

  const [sum, setSum] = useState(0);
  const [payed, setPayed] = useState(0);

  const [scholarshipList, setscholarshipList] = useState([]);

  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user && !UserIsSet) {
      setCurrentUser(user);
      setUserIsSet(true);
    }
  });

  async function getContentById(ids, path) {
    // don't run if there aren't any ids or a path for the collection
    if (!ids || !ids.length || !path) return [];

    const collectionPath = path;
    const batches = [];
    const batchTitles = new Set();

    while (ids.length) {
      // firestore limits batches to 10
      const batch = ids.splice(0, 10);

      // add the batch request to to a queue
      const q = query(collectionPath, where(documentId(), "in", batch));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        if (!batchTitles.has(doc.data().title)) {
          batches.push(doc);
          batchTitles.add(doc.data().title);
          if (doc.data().belopp > 0) {
            setSum((sum) => {
              return sum + doc.data().belopp;
            });
          }
        }
      });
    }
    // after all of the data is fetched, return it
    return Promise.all(batches).then((content) => content.flat());
  }

  useEffect(() => {
    if (currentUser) {
      const qRef = collection(db, "stipendier");

      async function profileCheck() {
        try {
          const ref2 = doc(db, "users", currentUser.uid);

          const docSnap2 = await getDoc(ref2);

          setPayed(docSnap2.data().payed);
          setscholarshipList(await getContentById(docSnap2.data().matches, qRef, setSum));

          setSiteLoad(false);
        } catch (e) {
          setSiteLoad(false);
        }
      }
      profileCheck(currentUser);
    }
  }, [currentUser]);

  useEffect(() => {
    if (isMobile) {
      setAlign("center");
    }
    if (isTablet) {
      setAlign("left");
    }
  }, []);

  async function handleLogout() {
    try {
      await auth.signOut();
      window.location.reload();
    } catch (e) {}
  }

  function updatePayed(pay) {
    const ref2 = doc(db, "users", currentUser.uid);

    return updateDoc(ref2, {
      payed: pay,
    });
  }

  var font_size = "1.1rem";

  if (isMobile) {
    font_size = "1rem";
  }
  const [showA, setShowA] = useState(true);
  const [showB, setShowB] = useState(true);
  const [showC, setShowC] = useState(true);

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          fontFamily: "Poppins",
        }}
      >
        {!currentUser ? (
          ///////////////////////////////////////////
          // Logged Out /////////////////////////////
          ///////////////////////////////////////////

          <div style={{ zIndex: "10" }}>
            <Router>
              <>
                <Navbar
                  key={false}
                  collapseOnSelect
                  expand={false}
                  variant="dark"
                  className="color-nav"
                  style={{ fontSize: font_size }}
                  sticky="top"
                >
                  <Container fluid style={{ padding: "0px", maxWidth: "1400px" }}>
                    {isDesktop ? (
                      <div>
                        <Navbar.Brand
                          as={Link}
                          to="/"
                          style={{ paddingLeft: "1vw", marginRight: "0px" }}
                        >
                          <img
                            src={logo}
                            width="60vw"
                            className="d-inline-block align-center"
                            alt=""
                          />{" "}
                          Stipendly
                        </Navbar.Brand>
                      </div>
                    ) : (
                      <div
                        style={{
                          margin: "0px",
                          padding: "0px",
                          minWidth: "20vw",
                        }}
                      >
                        <Navbar.Brand
                          as={Link}
                          to="/"
                          style={{
                            marginRight: "0px",
                            paddingLeft: "1rem",
                            minWidth: "20vw",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={logo}
                            width="50vw"
                            className="d-inline-block align-center"
                            alt=""
                            style={{
                              marginRight: "5px",
                              padding: "0px",
                            }}
                          />
                          Stipendly
                        </Navbar.Brand>
                      </div>
                    )}
                  </Container>
                </Navbar>
                <AuthProvider>
                  <Routes>
                    <>
                      <Route
                        path="/logga-in"
                        element={
                          <LoggedInRoute redirectTo={"/mina-stipendier"}>
                            <Login />
                          </LoggedInRoute>
                        }
                      />
                      <Route path="/skapa-konto" element={<Register />} />
                      <Route path="/glomt-losenord" element={<ForgotPassword />} />
                      <Route
                        path="/skapa-profil"
                        element={
                          <PrivateRoute redirectTo={"/logga-in"}>
                            <NonProfileRoute
                              payed={payed}
                              redirectTo={"/mina-stipendier"}
                            >
                              <UpdateProfile />
                            </NonProfileRoute>
                          </PrivateRoute>
                        }
                      />
                      <Route path="/skapa-konto" element={<Register />} />
                      <Route
                        path="/loading"
                        element={
                          <PrivateRoute redirectTo={"/loading"}>
                            <Loading />
                          </PrivateRoute>
                        }
                      />
                      <>
                        <Route
                          path="/mina-stipendier"
                          element={
                            <PrivateRoute redirectTo={"/logga-in"}>
                              <Dashboard
                                payed={payed}
                                scholarshipList={scholarshipList}
                                sum={sum}
                                showC={showC}
                                setShowC={setShowC}
                              />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/bokmarkta-stipendier"
                          element={
                            <PrivateRoute redirectTo={"/logga-in"}>
                              <Liked
                                payed={payed}
                                scholarshipList={scholarshipList}
                                showBA={showB}
                                setShowB={setShowB}
                              />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/"
                          element={
                            <PrivateRoute redirectTo={"/logga-in"}>
                              <Start payed={payed} showA={showA} setShowA={setShowA} />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/skapa-profil"
                          element={
                            <PrivateRoute redirectTo={"/logga-in"}>
                              <NonProfileRoute
                                payed={payed}
                                redirectTo={"/mina-stipendier"}
                              >
                                <UpdateProfile />
                              </NonProfileRoute>
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/installningar"
                          element={
                            <PrivateRoute redirectTo={"/installningar"}>
                              <Settings />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/losenord"
                          element={
                            <PrivateRoute redirectTo={"/losenord"}>
                              <Password />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="*"
                          element={
                            <PrivateRoute redirectTo={"/"}>
                              <NotFound />
                            </PrivateRoute>
                          }
                        />
                      </>
                    </>
                  </Routes>
                </AuthProvider>
              </>
            </Router>
          </div>
        ) : (
          ///////////////////////////////////////////
          // Logged In //////////////////////////////
          ///////////////////////////////////////////

          <div style={{ zIndex: "10" }}>
            <Router>
              <>
                <Navbar
                  key={false}
                  collapseOnSelect
                  expand={false}
                  variant="dark"
                  className="color-nav"
                  style={{ fontSize: font_size }}
                >
                  <Container fluid style={{ padding: "0px", maxWidth: "1400px" }}>
                    {isDesktop ? (
                      <div>
                        <Navbar.Brand
                          as={Link}
                          to="/"
                          style={{ paddingLeft: "1vw", marginRight: "0px" }}
                        >
                          <img
                            src={logo}
                            width="60vw"
                            className="d-inline-block align-center"
                            alt=""
                          />{" "}
                          Stipendly
                        </Navbar.Brand>
                      </div>
                    ) : (
                      <div
                        style={{
                          margin: "0px",
                          padding: "0px",
                          minWidth: "20vw",
                        }}
                      >
                        <Navbar.Brand
                          as={Link}
                          to="/"
                          style={{
                            marginRight: "0px",
                            padding: "0px",
                            minWidth: "20vw",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={logo}
                            width="50vw"
                            className="d-inline-block align-center"
                            alt=""
                            style={{
                              margin: "0px",
                              padding: "0px",
                            }}
                          />
                        </Navbar.Brand>
                      </div>
                    )}

                    <div style={{ flex: "1" }}>
                      <>
                        {isBrowser ? (
                          <div>
                            <Nav
                              className="me-auto"
                              style={{
                                flexDirection: "row",
                              }}
                            >
                              <Nav.Link as={Link} to="/">
                                <p
                                  style={{
                                    margin: "0px",
                                    paddingRight: "2vw",
                                    paddingLeft: "2vw",
                                  }}
                                >
                                  <House size={30} /> Hem
                                </p>
                              </Nav.Link>

                              <Nav.Link as={Link} to="/mina-stipendier">
                                <p style={{ margin: "0px", paddingRight: "2vw" }}>
                                  <CardList size={30} /> Mina stipendier
                                </p>
                              </Nav.Link>

                              <Nav.Link as={Link} to="/bokmarkta-stipendier">
                                <p style={{ margin: "0px", paddingRight: "2vw" }}>
                                  <Bookmarks size={30} /> Bokmärkta stipendier
                                </p>
                              </Nav.Link>

                              {payed === 0 && !siteLoad && (
                                <Nav.Link as={Link} to="/skapa-profil">
                                  <p style={{ margin: "0px", paddingRight: "2vw" }}>
                                    <PersonLinesFill size={30} /> Min profil
                                  </p>
                                </Nav.Link>
                              )}
                            </Nav>
                          </div>
                        ) : (
                          <div style={{ width: "60vw" }}>
                            <Nav
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                                flexDirection: "row",
                              }}
                            >
                              <Nav.Link as={Link} to="/">
                                <p
                                  style={{
                                    margin: "0px",
                                    marginLeft: "2vw",
                                    marginRight: "2vw",
                                  }}
                                >
                                  <House size={30} />
                                </p>
                              </Nav.Link>

                              <Nav.Link as={Link} to="/mina-stipendier">
                                <p style={{ margin: "0px" }}>
                                  <CardList size={30} />
                                </p>
                              </Nav.Link>

                              <Nav.Link as={Link} to="/bokmarkta-stipendier">
                                <p
                                  style={{
                                    margin: "0px",
                                    marginLeft: "2vw",
                                    marginRight: "2vw",
                                  }}
                                >
                                  <Bookmarks size={30} />
                                </p>
                              </Nav.Link>
                            </Nav>
                          </div>
                        )}
                      </>
                    </div>
                    {isBrowser ? (
                      <div>
                        <Navbar.Toggle
                          aria-controls={`offcanvasNavbar-expand-${false}`}
                          style={{ border: "none" }}
                        >
                          <List size={35} color="white" />
                        </Navbar.Toggle>

                        <Navbar.Offcanvas
                          id={`offcanvasNavbar-expand-${false}`}
                          aria-labelledby={`offcanvasNavbarLabel-expand-${false}`}
                          placement="end"
                          style={{ fontSize: font_size }}
                          align={align}
                        >
                          <Offcanvas.Header closeButton>
                            <Offcanvas.Title
                              id={`offcanvasNavbarLabel-expand-${false}`}
                              style={{ fontSize: "1.8rem" }}
                            >
                              Meny
                            </Offcanvas.Title>
                          </Offcanvas.Header>
                          <Offcanvas.Body
                            style={{
                              fontFamily: "Poppins",
                            }}
                          >
                            <Nav
                              className="justify-content-start flex-grow-1"
                              style={{ height: "100%" }}
                            >
                              <BrowserView>
                                <NavDropdown
                                  title={
                                    <>
                                      <Gear size={30} /> Inställningar
                                    </>
                                  }
                                  id={`offcanvasNavbarDropdownStart-expand-${false}`}
                                >
                                  <NavDropdown.Item
                                    as={Link}
                                    to="/installningar"
                                    eventKey="1"
                                  >
                                    <FilePerson size={25} /> Personlig information
                                  </NavDropdown.Item>
                                  <NavDropdown.Item as={Link} to="/losenord" eventKey="2">
                                    <Key size={25} /> Lösenord
                                  </NavDropdown.Item>
                                </NavDropdown>
                              </BrowserView>
                              <a
                                href="https://stipendly.se/fragor-och-svar/"
                                rel="noopener"
                                eventKey="1"
                                style={{
                                  padding: "0.5rem 0",
                                  color: "inherit",
                                  textDecoration: "none",
                                }}
                              >
                                <BrowserView>
                                  <QuestionCircle size={30} /> Frågor och svar
                                </BrowserView>
                              </a>
                              <a
                                href="https://stipendly.se/om-oss/"
                                rel="noopener"
                                eventKey="1"
                                style={{
                                  padding: "0.5rem 0",
                                  color: "inherit",
                                  textDecoration: "none",
                                }}
                              >
                                <BrowserView>
                                  <InfoCircle size={30} /> Om oss
                                </BrowserView>
                              </a>
                              <a
                                href="https://stipendly.se/blogg/"
                                rel="noopener"
                                eventKey="1"
                                style={{
                                  padding: "0.5rem 0",
                                  color: "inherit",
                                  textDecoration: "none",
                                }}
                              >
                                <BrowserView>
                                  <JournalRichtext size={30} /> Blogg
                                </BrowserView>
                              </a>
                              <a
                                href="https://stipendly.se/anvandarvillkor/"
                                rel="noopener"
                                eventKey="1"
                                style={{
                                  padding: "0.5rem 0",
                                  color: "inherit",
                                  textDecoration: "none",
                                }}
                              >
                                <BrowserView>
                                  <People size={30} /> Användarvillkor
                                </BrowserView>
                              </a>
                              <Nav.Link
                                as={Link}
                                to="/logga-in"
                                onClick={handleLogout}
                                eventKey="1"
                              >
                                <BrowserView>
                                  <ArrowRight size={30} /> Logga ut
                                </BrowserView>
                              </Nav.Link>
                            </Nav>
                          </Offcanvas.Body>
                        </Navbar.Offcanvas>
                      </div>
                    ) : (
                      <div
                        style={{
                          margin: "0px",
                          padding: "0px",
                          maxWidth: "20vw",
                          minWidth: "20vw",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Navbar.Toggle
                          aria-controls={`offcanvasNavbar-expand-${false}`}
                          style={{
                            margin: "0px",
                            padding: "0px",
                            border: "none",
                          }}
                        >
                          <List size={30} />
                        </Navbar.Toggle>

                        <Navbar.Offcanvas
                          id={`offcanvasNavbar-expand-${false}`}
                          aria-labelledby={`offcanvasNavbarLabel-expand-${false}`}
                          placement="end"
                          style={{ fontSize: font_size }}
                          align={align}
                        >
                          <Offcanvas.Header closeButton>
                            <Offcanvas.Title
                              id={`offcanvasNavbarLabel-expand-${false}`}
                              style={{ fontSize: "2rem" }}
                            >
                              Meny
                            </Offcanvas.Title>
                          </Offcanvas.Header>
                          <Offcanvas.Body
                            style={{
                              fontFamily: "Poppins",
                            }}
                          >
                            <Nav
                              className="justify-content-start flex-grow-1"
                              style={{ height: "100%" }}
                            >
                              <div
                                style={{
                                  height: "100%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  flexDirection: "column",
                                  fontSize: "1.4rem",
                                  padding: "3rem",
                                  paddingTop: "2rem",
                                }}
                              >
                                {payed === 0 && (
                                  <>
                                    <Nav.Link as={Link} to="/skapa-profil" eventKey="1">
                                      <MobileView>
                                        Min profil <PersonLinesFill size={30} />
                                      </MobileView>
                                      <BrowserView>
                                        <PersonLinesFill size={30} /> Min profil
                                      </BrowserView>
                                    </Nav.Link>
                                  </>
                                )}
                                <MobileView>
                                  <NavDropdown
                                    title={
                                      <>
                                        Inställningar <Gear size={30} />
                                      </>
                                    }
                                    id={`offcanvasNavbarDropdown-expand-${false}`}
                                  >
                                    <NavDropdown.Item
                                      as={Link}
                                      to="/installningar"
                                      eventKey="1"
                                      style={{ display: "flex", justifyContent: "end" }}
                                    >
                                      <span>
                                        Personlig information <FilePerson size={25} />
                                      </span>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                      as={Link}
                                      to="/losenord"
                                      eventKey="2"
                                      style={{ display: "flex", justifyContent: "end" }}
                                    >
                                      <span>
                                        Lösenord <Key size={25} />
                                      </span>
                                    </NavDropdown.Item>
                                  </NavDropdown>
                                </MobileView>
                                <a
                                  href="https://stipendly.se/fragor-och-svar/"
                                  rel="noopener"
                                  eventKey="1"
                                  style={{
                                    padding: "0.5rem 0",
                                    color: "inherit",
                                    textDecoration: "none",
                                  }}
                                >
                                  <MobileView>
                                    Frågor och svar <QuestionCircle size={30} />
                                  </MobileView>
                                </a>
                                <a
                                  href="https://stipendly.se/om-oss/"
                                  rel="noopener"
                                  eventKey="1"
                                  style={{
                                    padding: "0.5rem 0",
                                    color: "inherit",
                                    textDecoration: "none",
                                  }}
                                >
                                  <MobileView>
                                    Om oss <InfoCircle size={30} />
                                  </MobileView>
                                </a>
                                <a
                                  href="https://stipendly.se/blogg/"
                                  rel="noopener"
                                  eventKey="1"
                                  style={{
                                    padding: "0.5rem 0",
                                    color: "inherit",
                                    textDecoration: "none",
                                  }}
                                >
                                  <MobileView>
                                    Blogg <JournalRichtext size={30} />
                                  </MobileView>
                                </a>
                                <a
                                  href="https://stipendly.se/anvandarvillkor/"
                                  rel="noopener"
                                  eventKey="1"
                                  style={{
                                    padding: "0.5rem 0",
                                    color: "inherit",
                                    textDecoration: "none",
                                  }}
                                >
                                  <MobileView>
                                    Användarvillkor <People size={30} />
                                  </MobileView>
                                </a>
                                <Nav.Link
                                  as={Link}
                                  to="/logga-in"
                                  onClick={handleLogout}
                                  eventKey="1"
                                >
                                  <MobileView>
                                    Logga ut <ArrowRight size={30} />
                                  </MobileView>
                                </Nav.Link>
                              </div>
                            </Nav>
                          </Offcanvas.Body>
                        </Navbar.Offcanvas>
                      </div>
                    )}
                  </Container>
                </Navbar>
                {siteLoad ? (
                  <SiteLoad />
                ) : (
                  <>
                    <div
                      className="completion-bar"
                      style={payed === 2 ? { display: "none" } : { position: "relative" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {payed === 0 ? (
                          <>
                            <CircleFill
                              size={20}
                              fill={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue("--main-color")}
                              style={
                                isMobile
                                  ? { width: "100px", zIndex: "50", marginLeft: "5%" }
                                  : {
                                      width: "100px",
                                      zIndex: "50",
                                      marginLeft: "25%",
                                    }
                              }
                            />
                            <hr
                              className="lineNotFill"
                              style={{
                                marginLeft: "-50px",
                                marginRight: "-50px",
                                zIndex: "1",
                              }}
                            />
                            <CircleFill
                              size={20}
                              fill={"#a1a1a1"}
                              style={{
                                width: "100px",
                                zIndex: "50",
                              }}
                            />
                            <hr
                              className="lineNotFill"
                              style={{ marginLeft: "-50px", marginRight: "-50px" }}
                            />
                            <SquareFill
                              size={14}
                              fill={"#a1a1a1"}
                              style={
                                isMobile
                                  ? {
                                      width: "100px",
                                      zIndex: "50",
                                      marginRight: "5%",
                                    }
                                  : {
                                      width: "100px",
                                      zIndex: "50",
                                      marginRight: "25%",
                                    }
                              }
                            />
                          </>
                        ) : payed === 1 ? (
                          <>
                            <CircleFill
                              size={20}
                              fill={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue("--main-color")}
                              style={
                                isMobile
                                  ? { width: "100px", zIndex: "50", marginLeft: "5%" }
                                  : {
                                      width: "100px",
                                      zIndex: "50",
                                      marginLeft: "25%",
                                    }
                              }
                            />
                            <hr
                              className="line"
                              style={{
                                marginLeft: "-50px",
                                marginRight: "-50px",
                                zIndex: "1",
                              }}
                            />
                            <CircleFill
                              size={20}
                              fill={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue("--main-color")}
                              style={{
                                width: "100px",
                                zIndex: "50",
                              }}
                            />
                            <hr
                              className="lineNotFill"
                              style={{ marginLeft: "-50px", marginRight: "-50px" }}
                            />
                            <SquareFill
                              size={14}
                              fill={"#a1a1a1"}
                              style={
                                isMobile
                                  ? {
                                      width: "100px",
                                      zIndex: "50",
                                      marginRight: "5%",
                                    }
                                  : {
                                      width: "100px",
                                      zIndex: "50",
                                      marginRight: "25%",
                                    }
                              }
                            />
                          </>
                        ) : payed === 2 ? (
                          <>
                            <CircleFill
                              size={20}
                              fill={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue("--main-color")}
                              style={
                                isMobile
                                  ? { width: "100px", zIndex: "50", marginLeft: "5%" }
                                  : {
                                      width: "100px",
                                      zIndex: "50",
                                      marginLeft: "25%",
                                    }
                              }
                            />
                            <hr
                              className="line"
                              style={{
                                marginLeft: "-50px",
                                marginRight: "-50px",
                                zIndex: "1",
                              }}
                            />
                            <CircleFill
                              size={20}
                              fill={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue("--main-color")}
                              style={{
                                width: "100px",
                                zIndex: "50",
                              }}
                            />
                            <hr
                              className="line"
                              style={{ marginLeft: "-50px", marginRight: "-50px" }}
                            />
                            <SquareFill
                              size={14}
                              fill={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue("--main-color")}
                              style={
                                isMobile
                                  ? {
                                      width: "100px",
                                      zIndex: "50",
                                      marginRight: "5%",
                                    }
                                  : {
                                      width: "100px",
                                      zIndex: "50",
                                      marginRight: "25%",
                                    }
                              }
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            position: "sticky",
                          }}
                        >
                          {payed === 0 ? (
                            <Link
                              to={"/skapa-profil"}
                              style={
                                isMobile
                                  ? {
                                      marginLeft: "5%",
                                      width: "100px",
                                      marginBottom: "1rem",
                                      textDecoration: "underline !important",
                                    }
                                  : {
                                      marginLeft: "25%",
                                      width: "100px",
                                      marginBottom: "1rem",
                                      textDecoration: "underline  !important",
                                    }
                              }
                            >
                              Skapa
                              <br /> profil
                            </Link>
                          ) : (
                            <p
                              style={
                                isMobile
                                  ? { marginLeft: "5%", width: "100px" }
                                  : { marginLeft: "25%", width: "100px" }
                              }
                            >
                              Skapa <br /> profil
                            </p>
                          )}
                          {payed === 1 ? (
                            <Link
                              to={"/mina-stipendier"}
                              style={
                                isMobile
                                  ? {
                                      width: "100px",
                                      marginBottom: "1rem",
                                      textDecoration: "underline !important",
                                    }
                                  : {
                                      width: "100px",
                                      marginBottom: "1rem",
                                      textDecoration: "underline  !important",
                                    }
                              }
                            >
                              Granska
                              <br />
                              matchningar
                            </Link>
                          ) : (
                            <p style={{ width: "100px" }}>
                              Granska
                              <br />
                              matchningar
                            </p>
                          )}

                          <p
                            style={
                              isMobile
                                ? { marginRight: "5%", width: "100px" }
                                : { marginRight: "25%", width: "100px" }
                            }
                          >
                            Se <br /> stipendier
                          </p>
                        </div>
                        {payed === 0 ? (
                          <></>
                        ) : payed === 1 ? (
                          <p
                            style={{
                              maxWidth: "50rem",
                              width: "85vw",
                              marginBottom: "0rem",
                            }}
                          >
                            Att köpa tjänsten är en engångskostnad som låser upp din lista
                            på stipendier, och visar deras namn, information, och länk
                            till ansökan. Att köpa tjänsten är helt frivilligt och efter
                            köp så hittas listan på sidan "Mina Stipendier". <br />
                            <a
                              href="https://se.trustpilot.com/review/stipendly.se"
                              rel="noreferrer"
                              target="_blank"
                            >
                              <img
                                style={{
                                  minWidth: isMobile ? "60%" : "28%",
                                  maxWidth: "60%",
                                  paddingTop: "0.75rem",
                                }}
                                src={omdome}
                                alt="omdome"
                              />
                            </a>
                          </p>
                        ) : payed === 2 ? (
                          <p style={{ maxWidth: "50rem", width: "85vw" }}>
                            Din profil är komplett och betald! <br /> Tack för att du
                            använder <span className="red-text"> Stipendly</span>!
                          </p>
                        ) : (
                          <></>
                        )}
                      </>
                    </div>
                    {payed === 2 && (
                      <div className="completion-bar" style={{ paddingTop: "1rem" }}>
                        <p style={{ maxWidth: "50rem", width: "85vw" }}>
                          Din profil är komplett och betald! <br /> Tack för att du
                          använder <span className="red-text"> Stipendly</span>!
                        </p>
                      </div>
                    )}
                    {payed === 1 && (
                      <div
                        style={{
                          backgroundColor: "#dcf2ff",
                          width: "100%",
                          position: "sticky",
                          top: "0",
                          zIndex: "1000",
                          padding: "1rem 0",
                        }}
                      >
                        <Nav.Link
                          as={Link}
                          to="/till-betalning"
                          style={{
                            width: "100vw",
                            height: "100%",
                            zIndex: "100",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textDecoration: "none",
                          }}
                        >
                          <span
                            class="rounded-pill !important"
                            style={{
                              minWidth: isMobile ? "80%" : "30%",
                              maxWidth: "80%",
                              fontSize: "1.5rem",
                              padding: "0.8rem 1.3rem",
                              backgroundColor: "#253e5c",
                              color: "white",
                              textAlign: "center",
                              paddingBottom: "1rem",
                            }}
                          >
                            Till betalning{" "}
                            <ArrowRightCircle size={32} className="iconWeight" />
                          </span>
                        </Nav.Link>
                      </div>
                    )}

                    <div className="completion-triangle"></div>
                  </>
                )}

                <AuthProvider>
                  <Routes>
                    <>
                      <Route
                        path="/logga-in"
                        element={
                          <LoggedInRoute redirectTo={"/mina-stipendier"}>
                            <Login />
                          </LoggedInRoute>
                        }
                      />
                      <Route path="/skapa-konto" element={<Register />} />
                      <Route path="/glomt-losenord" element={<ForgotPassword />} />
                      <Route
                        path="/skapa-profil"
                        element={
                          <PrivateRoute redirectTo={"/logga-in"}>
                            <NonProfileRoute
                              payed={payed}
                              redirectTo={"/mina-stipendier"}
                            >
                              <UpdateProfile />
                            </NonProfileRoute>
                          </PrivateRoute>
                        }
                      />
                      <Route path="/skapa-konto" element={<Register />} />
                      <Route
                        path="/loading"
                        element={
                          <PrivateRoute redirectTo={"/loading"}>
                            <Loading />
                          </PrivateRoute>
                        }
                      />
                      <>
                        <Route
                          path="/mina-stipendier"
                          element={
                            <PrivateRoute redirectTo={"/logga-in"}>
                              <Dashboard
                                payed={payed}
                                scholarshipList={scholarshipList}
                                sum={sum}
                                showC={showC}
                                setShowC={setShowC}
                              />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/bokmarkta-stipendier"
                          element={
                            <PrivateRoute redirectTo={"/logga-in"}>
                              <Liked
                                payed={payed}
                                scholarshipList={scholarshipList}
                                showB={showB}
                                setShowB={setShowB}
                              />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/"
                          element={
                            <PrivateRoute redirectTo={"/logga-in"}>
                              <Start payed={payed} showA={showA} setShowA={setShowA} />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/skapa-profil"
                          element={
                            <PrivateRoute redirectTo={"/logga-in"}>
                              <NonProfileRoute
                                payed={payed}
                                redirectTo={"/mina-stipendier"}
                              >
                                <UpdateProfile />
                              </NonProfileRoute>
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/installningar"
                          element={
                            <PrivateRoute redirectTo={"/installningar"}>
                              <Settings />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/losenord"
                          element={
                            <PrivateRoute redirectTo={"/losenord"}>
                              <Password />
                            </PrivateRoute>
                          }
                        />

                        <Route
                          path="/till-betalning"
                          element={
                            <PrivateRoute redirectTo={"/logga-in"}>
                              {payed === 1 && <ToPayment />}
                            </PrivateRoute>
                          }
                        />
                        {/* <Route
                          path=":username"
                          element={
                            <PrivateRoute redirectTo={"/logga-in"}>
                              <Payed updatePayed={updatePayed} />
                            </PrivateRoute>
                          }
                        /> */}
                        <Route
                          path="b16de8f73f012819db17130988ce5d84"
                          element={
                            <PrivateRoute redirectTo={"/logga-in"}>
                              <Payed updatePayed={updatePayed} />
                            </PrivateRoute>
                          }
                        />
                        <Route path="not-found" element={<NotFound />} />
                        <Route path="*" element={<NotFound />} />
                      </>
                    </>
                  </Routes>
                </AuthProvider>
              </>
            </Router>
          </div>
        )}
      </div>

      {isDesktop || isTablet ? <FooterBrowser /> : <FooterMobile />}
    </div>
  );
}
export default App;
