import React, { useRef, useState } from "react";
import { useAuth } from "../contexts/AuthContext.js";
import { Form, Button, Alert, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function ForgotPassword() {
  const emailRef = useRef();
  const { resetPassword } = useAuth();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      try {
        setMessage("");
        setError("");
        setLoading(true);
        await resetPassword(emailRef.current.value);
        setMessage("Kolla din mail för vidare instruktioner");
      } catch (e) {
        setError("Kunde inte återställa lösenord");
      }
    }

    setValidated(true);
    setLoading(false);
  }
  return (
    <div
      style={{
        minHeight: "90vh",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        position: "relative",
        flex: "1",
      }}
    >
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        style={{ maxWidth: "25rem", width: "75vw" }}
      >
        <div style={{ textAlign: "center", padding: "35px" }}>
          <h3>Återställ Lösenord</h3>
        </div>
        {error && <Alert variant="danger">{error}</Alert>}
        {message && <Alert variant="success">{message}</Alert>}

        <div class="form-outline mb-4">
          <Form.Group id="email" as={Col} md="20" controlId="validationCustom01">
            <Form.Label>E-post</Form.Label>
            <Form.Control required type="email" ref={emailRef} placeholder="E-post" />
            <Form.Control.Feedback type="invalid">Måste fyllas i.</Form.Control.Feedback>
          </Form.Group>
        </div>

        <Button
          disabled={loading}
          type="submit"
          variant="flat"
          class="btn btn-primary btn-block mb-3"
          className="button"
        >
          Återställ Lösenord
        </Button>
        <div class="text-center" style={{ paddingTop: "5%" }}>
          <p>
            <Link to="/logga-in">Tillbaka till inloggning</Link>
          </p>
        </div>
      </Form>
    </div>
  );
}
