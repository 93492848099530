import React from "react";
import { Link } from "react-router-dom";

export default function Login() {
  return (
    <div
      className="Register"
      style={{
        minHeight: "60vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        position: "relative",
        flex: "1",
      }}
    >
      <div style={{ textAlign: "center", padding: "35px"}}>
        <h1>
          404: Sidan du försöker <span className="red-text"> besöka</span> finns inte!
        </h1>
      </div>
      <div class="text-center">
        <p style={{fontSize:"1.5rem" }}>
          <Link to="/">Tillbaka till startsidan</Link>
        </p>
      </div>
    </div>
  );
}