import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import "../App.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Payed({ updatePayed }) {
  const [siteLoad, setSiteLoad] = useState(true);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [showLink, setShowLink] = useState(false);


  useEffect(() => {
    if (siteLoad === false) {
      setTimeout(() => {
        navigate("/mina-stipendier/", { state: { previousPath: pathname } });
      }, 3000);
    }

    setTimeout(() => {
      setShowLink(true);
    }, 8000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteLoad]); 

  useEffect(() => {
    async function setNewPayed() {
      try {
        await updatePayed(2);
      } catch (e) {
      }
      setSiteLoad(false);
    }
    setNewPayed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: "0",
        left: "0",
        zIndex: "100",
        background: "white",
      }}
    >
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "80%",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", marginBottom: "3rem" }}>
          <h4 style={{ marginRight: "1rem" }}>
            Tack för din betalning! Du omdirigeras nu till Stipendly.
          </h4>
          <div>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
        {showLink && (
          <p style={{ fontSize: "1.2rem", textAlign: "center" }}>
            Händer det inget?{" "}
            <Link to={"/mina-stipendier"} state={{ previousPath: pathname }}>
              {" "}
              Klicka här!
            </Link>
          </p>
        )}
      </div>
    </div>
  );
}
