import React, { useState } from "react";
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  ToggleButton,
  Placeholder,
} from "react-bootstrap";
import "../App.css";
import { isMobile } from "react-device-detect";
import {
  Bookmark,
  UnlockFill,
} from "react-bootstrap-icons";
import { format, tags } from "./HelperFunctions";

// BLUR ////////////////////////////////

export function BlurStipendie({
  doc,
  selectedQ1,
  selectedQ2,
  selectedQ3,
  selectedQ4,
  selectedQ5,
  selectedQ6,
  selectedQ7,
  selectedQ8,
  selectedQ9,
  selectedQ10,
  selectedQ11,
  selectedQ12,
  selectedQ13,
  selectedQ14,
  selectedQ15,
}) {
  const [checked, setChecked] = useState(false);

  async function toggleCheck() {
    setChecked(!checked);
  }

  return (
    <>
      <Container
        style={{
          maxWidth: "50rem",
          width: "98%",
          marginBottom: "3rem",
          marginTop: "0rem",
        }}
      >
        <Card
          className="text-center shadow"
          style={{ border: "2px solid", borderRadius: "8px" }}
        >
          <Card.Header className="blue">
            <Card.Title
              key={"nisi lacus sed"}
              style={{
                width: "100%",
                margin: "0px",
              }}
            >
              <Container
                style={{
                  margin: "0px",
                  padding: "0px",
                }}
              >
                <Placeholder as={Card.Title} animation="glow">
                  <Row>
                    <Col
                      md={11}
                      sm={10}
                      xs={10}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "left",
                      }}
                    >
                      <Placeholder xs={5} />
                    </Col>
                    <Col md={1} sm={2} xs={2}>
                      <ToggleButton
                        className="bookmarkToggle"
                        type="checkbox"
                        variant="outline-light"
                        checked={checked}
                        value="1"
                        onClick={toggleCheck}
                        style={{
                          fontSize: "18px",
                          border: "none",
                          margin: "none",
                          padding: "none",
                        }}
                      >
                        <Bookmark
                          style={{
                            scale: "1.3",
                          }}
                        />
                      </ToggleButton>
                    </Col>
                  </Row>
                </Placeholder>
              </Container>
            </Card.Title>
          </Card.Header>
          {tags([
            selectedQ1[0],
            selectedQ2[0],
            selectedQ3[0],
            selectedQ4[0],
            selectedQ5[0],
            selectedQ6[0],
            selectedQ7[0],
            selectedQ8[0],
            selectedQ9[0],
            selectedQ10[0],
            selectedQ11[0],
            selectedQ12[0],
            selectedQ13[0],
            selectedQ14[0],
            selectedQ15[0],
          ]) ? (
            <Card.Header>
              <Row style={{ textAlign: "left" }}>
                <Col>
                  {tags([
                    selectedQ1[0],
                    selectedQ2[0],
                    selectedQ3[0],
                    selectedQ4[0],
                    selectedQ5[0],
                    selectedQ6[0],
                    selectedQ7[0],
                    selectedQ8[0],
                    selectedQ9[0],
                    selectedQ10[0],
                    selectedQ11[0],
                    selectedQ12[0],
                    selectedQ13[0],
                    selectedQ14[0],
                    selectedQ15[0],
                  ])}
                </Col>
              </Row>
            </Card.Header>
          ) : (
            <></>
          )}
          <Card.Body>
            <Card.Text
              style={{
                paddingTop: "0.5vh",
                paddingBottom: "0.5vh",
                textAlign: isMobile ? "left" : "justify",
              }}
            >
              <Placeholder as={Card.Text} animation="glow">
                <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={5} />
                <Placeholder xs={7} /> <Placeholder xs={2} /> <Placeholder xs={9} />
                <Placeholder xs={10} /> <Placeholder xs={1} /> <Placeholder xs={4} />
              </Placeholder>
            </Card.Text>
          </Card.Body>
          <Card.Footer style={{ backgroundColor: "#F8F8F8" }} className="text-muted">
            <Container>
              <Row
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Col>
                  <span style={{ color: "#2e7d32" }}>
                    Belopp: {format(doc.data().belopp)}
                  </span>
                </Col>
                <Col>
                  <div>
                    <Button
                      variant="flat"
                      class="btn btn-primary btn-block mb-3"
                      className="button"
                    >
                      <UnlockFill size={20} style={{ paddingBottom: "0.2rem" }} /> Låses
                      upp vid betalning
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
}
