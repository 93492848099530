import React from "react";
import { Spinner } from "react-bootstrap";

export default function Loading() {
  return (
      <>
        <div
          style={{
            width: "100%",
            height: "89vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <h4 style={{ marginRight: "1rem" }}>Laddar Stipendly</h4>

            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
    </>
  );
}
