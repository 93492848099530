import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  FilePerson,
  House,
  InfoCircle,
  JournalRichtext,
  Key,
  People,
  QuestionCircle,
} from "react-bootstrap-icons";
import { isMobile } from "react-device-detect";
import "../App.css";
import { useAuth } from "../contexts/AuthContext";
import { Link } from "react-router-dom";
import InfoToast from "./InfoToast";
import { funFacts } from "./Constants";
import Article from "./Article";

export default function Start({ payed, showA, setShowA }) {
  const { currentUser } = useAuth();

  function getRandomInt(max) {
    let num = Math.floor(Math.random() * max);
    if (num !== 0) {
      num = Math.floor(Math.random() * max);
    }
    if (num !== 0) {
      num = Math.floor(Math.random() * max);
    }
    return num;
  }

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <>
          <InfoToast
            show={showA}
            setShow={setShowA}
            from={"Start"}
            icon={<House size={80} />}
            h1Text={
              payed === 0
                ? [
                    "Hej",
                    <span className="red-text"> {currentUser.displayName}</span>,
                    "!",
                  ]
                : payed === 1
                ? ["Mitt ", <span className="red-text"> Hem</span>]
                : payed === 2
                ? [
                    "Hej",
                    <span className="red-text"> {currentUser.displayName}</span>,
                    ", välkommen tillbaka!",
                  ]
                : []
            }
            pText={
              payed === 0
                ? [
                    "För att kunna använda Stipendly måste du först ",
                    <Link to={"/skapa-profil"}>skapa en profil</Link>,
                    "!",
                  ]
                : payed === 1
                ? [
                    currentUser.displayName,
                    ", dina",
                    <span className="red-text"> stipendier </span>,
                    "är nästan redo att visas! ",
                    <br />,
                    <Link to={"/mina-stipendier"}>Granska dina matchningar</Link>,
                  ]
                : payed === 2
                ? funFacts[getRandomInt(funFacts.length)]
                : []
            }
          />
          <div
            style={{
              width: isMobile ? "93%" : "80%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              border: "solid",
              borderRadius: "40px",
              padding: "1rem",
              textAlign: "center",
              marginBottom: "4rem",
              maxWidth: "1100px",
            }}
          >
            <h2 style={{ marginTop: "1.2rem", marginBottom: "2.5rem" }}>
              Min <span className="red-text"> Kontrollpanel</span>
            </h2>
            <Container
              style={{
                textAlign: "center",
                textDecoration: "underline",
                fontSize: "1.4rem",
              }}
            >
              <Row>
                <Col sm style={{ marginBottom: "2rem" }}>
                  <FilePerson size={35} />
                  <Link to={"/installningar"} className="home-links">
                    Personlig information
                  </Link>
                </Col>
                <Col sm style={{ marginBottom: "2rem" }}>
                  <Key size={35} />
                  <Link to={"/losenord"} className="home-links">
                    Lösenord
                  </Link>
                </Col>
                <Col sm style={{ marginBottom: "2rem" }}>
                  <QuestionCircle size={35} />
                  <a
                    href="https://stipendly.se/fragor-och-svar/"
                    rel="noopener"
                    class="px-2"
                    className="home-links"
                  >
                    Frågor och svar
                  </a>
                </Col>
              </Row>
              <Row>
                <Col sm style={{ marginBottom: "2rem" }}>
                  <InfoCircle size={35} />
                  <a
                    href="https://stipendly.se/om-oss/"
                    rel="noopener"
                    class="px-2"
                    className="home-links"
                  >
                    Om oss
                  </a>
                </Col>
                <Col sm style={{ marginBottom: "2rem" }}>
                  <JournalRichtext size={35} />
                  <a
                    href="https://stipendly.se/blogg"
                    rel="noopener"
                    class="px-2"
                    className="home-links"
                  >
                    Blogg
                  </a>
                </Col>
                <Col sm style={{ marginBottom: "2rem" }}>
                  <People size={35} />
                  <a
                    href="https://stipendly.se/anvandarvillkor/"
                    rel="noopener"
                    class="px-2"
                    className="home-links"
                  >
                    Användarvillkor
                  </a>
                </Col>
              </Row>
            </Container>
          </div>
        </>
        <h2 style={{ marginBottom: "2rem" }}>
          <span className="red-text"> Nyheter </span>
          från bloggen
        </h2>
        <div class="row mb-2" style={{ maxWidth: "82rem", width: "98%" }}>
          {/* ///////////////////////////////////
          ARTICLE ONE 
          ///////////////////////////////////////*/}

          <Article
            subject={"Intervju"}
            header={"Allt du behöver veta för att komma igång med ditt stipendiesökande"}
            paragraph={"Att söka stipendier kan vara..."}
            img={require("../images/danne.png")}
            link={
              "https://stipendly.se/blogg/allt-du-behover-veta-for-att-komma-igang-med-ditt-stipendiesokande/"
            }
            pos={"70% 100%"}
          />

          {/* ///////////////////////////////////
          ARTICLE TWO
          ///////////////////////////////////////*/}
          <Article
            subject={"Tips"}
            header={"Våra 5 bästa tips för en bra stipendieansökan!"}
            paragraph={
              "Miljontals kronor delas ut via olika stipendier till mottagare i Sverige varje år..."
            }
            img={require("../images/pen.jpg")}
            link={
              "https://stipendly.se/blogg/stipendlys-5-basta-tips-for-en-bra-stipendieansokan/"
            }
            pos={"60% 100%"}
          />
        </div>
        <div class="row mb-4" style={{ maxWidth: "82rem", width: "98%" }}>
          {/* ///////////////////////////////////
          ARTICLE THREE
          ///////////////////////////////////////*/}
          <Article
            subject={"Tips"}
            header={"Så gör du en bra motivering när du söker tillskott från stipendier"}
            paragraph={"Miljontals kronor..."}
            img={require("../images/currency.png")}
            link={
              "https://stipendly.se/blogg/sa-gor-du-en-bra-motivering-nar-du-soker-tillskott-fran-stipendier/"
            }
            pos={"50% 100%"}
          />

          {/* ///////////////////////////////////
          ARTICLE FOUR
          ///////////////////////////////////////*/}
          <Article
            subject={"Tips"}
            header={"Så maximerar du chanserna till stipendium"}
            paragraph={"När du väl har din lista på stipender från Stipendly..."}
            img={require("../images/friends.png")}
            link={
              "https://stipendly.se/blogg/maximera-chanserna-till-stipendium-med-din-lista-fran-stipendly/"
            }
            pos={"100% 100%"}
          />
        </div>
      </div>
    </>
  );
}
