import React, { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext.js";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  universities,
  kommuner,
  lan,
  countries,
  fakultet,
  studieomrade,
  uppsats,
} from "./Constants";
import "../App.css";
import { isMobile } from "react-device-detect";
import { isBrowser } from "@firebase/util";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase.js";
import { PersonLinesFill } from "react-bootstrap-icons";

export default function UpdateProfile() {
  const { createProfile } = useAuth();
  const { pathname } = useLocation();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [selectedQ1, setSelectedQ1] = useState([]);
  const [selectedQ2, setSelectedQ2] = useState([]);
  const [selectedQ3, setSelectedQ3] = useState([]);
  const [selectedQ4, setSelectedQ4] = useState([]);
  const [selectedQ5, setSelectedQ5] = useState([]);
  const [selectedQ6, setSelectedQ6] = useState([]);
  const [selectedQ7, setSelectedQ7] = useState([]);
  const [selectedQ8, setSelectedQ8] = useState([]);
  const [selectedQ9, setSelectedQ9] = useState([]);
  const [selectedQ10, setSelectedQ10] = useState([]);
  const [selectedQ11, setSelectedQ11] = useState([]);
  const [selectedQ12, setSelectedQ12] = useState([]);
  const [selectedQ13, setSelectedQ13] = useState([]);
  const [selectedQ14, setSelectedQ14] = useState([]);
  const [selectedQ15, setSelectedQ15] = useState([]);
  const [selectedQ16, setSelectedQ16] = useState([]);

  async function handleSubmit(e) {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      const scholarshipTitles = new Set();

      async function loadAll(q, profile) {
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          if (
            (profile[0].some((item) =>
              doc.data().Q1.some((itemInner) => item === itemInner)
            ) ||
              doc.data().Q1.length === 0) &&
            (profile[1].some((item) =>
              doc.data().Q2.some((itemInner) => item === itemInner)
            ) ||
              doc.data().Q2.length === 0) &&
            (profile[2].some((item) =>
              doc.data().Q3.some((itemInner) => item === itemInner)
            ) ||
              doc.data().Q3.length === 0) &&
            (profile[3].some((item) =>
              doc.data().Q4.some((itemInner) => item === itemInner)
            ) ||
              doc.data().Q4.length === 0) &&
            (profile[4].some((item) =>
              doc.data().Q5.some((itemInner) => item === itemInner)
            ) ||
              doc.data().Q5.length === 0) &&
            (profile[5].some((item) =>
              doc.data().Q6.some((itemInner) => item === itemInner)
            ) ||
              doc.data().Q6.length === 0) &&
            (profile[6].some((item) =>
              doc.data().Q7.some((itemInner) => item === itemInner)
            ) ||
              doc.data().Q7.length === 0) &&
            (profile[7].some((item) =>
              doc.data().Q8.some((itemInner) => item === itemInner)
            ) ||
              doc.data().Q8.length === 0) &&
            (profile[8].some((item) =>
              doc.data().Q9.some((itemInner) => item === itemInner)
            ) ||
              doc.data().Q9.length === 0) &&
            (profile[9].some((item) =>
              doc.data().Q10.some((itemInner) => item === itemInner)
            ) ||
              doc.data().Q10.length === 0) &&
            (profile[10].some((item) =>
              doc.data().Q11.some((itemInner) => item === itemInner)
            ) ||
              doc.data().Q11.length === 0) &&
            (profile[11].some((item) =>
              doc.data().Q12.some((itemInner) => item === itemInner)
            ) ||
              doc.data().Q12.length === 0) &&
            (profile[12].some((item) =>
              doc.data().Q13.some((itemInner) => item === itemInner)
            ) ||
              doc.data().Q13.length === 0) &&
            (profile[13].some((item) =>
              doc.data().Q14.some((itemInner) => item === itemInner)
            ) ||
              doc.data().Q14.length === 0) &&
            (profile[14].some((item) =>
              doc.data().Q15.some((itemInner) => item === itemInner)
            ) ||
              doc.data().Q15.length === 0) &&
            (profile[15].some((item) =>
              doc.data().Q16.some((itemInner) => item === itemInner)
            ) ||
              doc.data().Q16.length === 0)
          ) {
            scholarshipTitles.add(doc.id);
          }
        });
        return new Promise((resolve) => {
          resolve(Array.from(scholarshipTitles));
        });
      }
      const qRef = collection(db, "stipendier");
      const q = query(qRef, where("Q11", "in", [[], selectedQ11]));

      try {
        setError("");
        setLoading(true);

        let Q1 = [selectedQ1.value];
        let Q2 = [selectedQ2.value];
        let Q3 = [];
        selectedQ3.map((o) => Q3.push(o.value));
        let Q4 = [];
        selectedQ4.map((o) => Q4.push(o.value));
        let Q5 = [];
        selectedQ5.map((o) => Q5.push(o.value));
        let Q6 = [];
        selectedQ6.map((o) => Q6.push(o.value));
        let Q7 = [];
        selectedQ7.map((o) => Q7.push(o.value));
        let Q8 = [selectedQ8.value];
        let Q9 = [selectedQ9.value];
        let Q10 = [selectedQ10.value];
        let Q11 = [selectedQ11.value];
        let Q12 = [selectedQ12.value];
        let Q13 = [selectedQ13.value];
        let Q14 = [selectedQ14.value];
        let Q15 = [selectedQ15.value];
        let Q16 = [];
        selectedQ16.map((o) => Q16.push(o.value));

        await createProfile(
          selectedQ1,
          selectedQ2,
          selectedQ3,
          selectedQ4,
          selectedQ5,
          selectedQ6,
          selectedQ7,
          selectedQ8,
          selectedQ9,
          selectedQ10,
          selectedQ11,
          selectedQ12,
          selectedQ13,
          selectedQ14,
          selectedQ15,
          selectedQ16,
          1,
          [],
          await loadAll(q, [
            Q1,
            Q2,
            Q3,
            Q4,
            Q5,
            Q6,
            Q7,
            Q8,
            Q9,
            Q10,
            Q11,
            Q12,
            Q13,
            Q14,
            Q15,
            Q16,
            1,
            [],
          ])
        );
        setValidated(true);

        navigate("/mina-stipendier/", { state: { previousPath: pathname } });
        navigate(0, { state: { previousPath: pathname } });
      } catch (e) {
        setError("Alla frågor är inte besvarade");
      }
    }
    setValidated(true);
    setLoading(false);
  }

  if (isMobile) {
    var font_size = "1rem";
  } else if (isBrowser) {
    font_size = "1.2rem";
  }

  return (
    <>
      <div
        className="Register"
        style={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
          position: "relative",
          flex: "1",
          fontSize: font_size,
        }}
      >
        <Card
          style={{
            marginBottom: "5rem",
            marginLeft: "10px",
            marginRight: "10px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            flex: "1",
            fontSize: font_size,
            maxWidth: "50rem",
            width: "90vw",
            zIndex: "100",
            background: "none",
            border: isMobile ? "none" : "solid",
            borderRadius: "40px",
            overflow: "visible",
          }}
        >
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            style={{
              maxWidth: "41rem",
              width: "90vw",
              zIndex: "100",
              paddingTop: "1rem",
            }}
          >
            <div style={{ paddingBottom: "5rem", zIndex: "10" }}>
              <div style={{ textAlign: "center", marginBottom: "2rem" }}>
                <PersonLinesFill size={80} />
                <h1 style={{ marginTop: "1.5rem", marginBottom: "2rem" }}>
                  Min
                  <span className="red-text"> Profil</span> <br />
                </h1>
              </div>
              <div
                style={{
                  background: getComputedStyle(document.documentElement).getPropertyValue(
                    "--main-color"
                  ),
                  padding: "1rem",
                  borderRadius: "0.3rem",
                }}
              >
                <p style={{ color: "white", marginBottom: "0" }}>
                  Välkommen till Stipendly! För att kunna matcha dig till stipendier måste
                  du först fylla i din profil. Detta gör du på denna sida och att skapa en
                  profil är helt gratis.
                  <br />
                  <br />
                  För bästa möjliga matchning, Svara så utförligt som möjligt på alla
                  frågor och välj alternativet "Inget/Inte applicerbart" på frågorna som
                  inte är relevanta för dig.
                </p>
              </div>
              <div
                style={{
                  width: "0",
                  height: "0",
                  borderLeft: "20px solid transparent",
                  borderRight: "20px solid transparent",
                  borderTop: "20px solid #253e5c",
                  margin: "0 auto",
                  marginBottom: "2rem",
                }}
              ></div>

              <div class="form-outline mb-5">
                <Form.Label style={{ fontSize: "1.5rem" }}>
                  1. Mitt <span className="red-text"> Kön</span>
                  <p
                    style={{
                      fontSize: "1.05rem",
                      marginBottom: "0.2rem",
                      marginTop: "0.2rem",
                    }}
                  >
                    Ange ditt kön genom att välja i textrutan.
                  </p>
                </Form.Label>
                <Select
                  isClearable={true}
                  isSearchable={false}
                  value={selectedQ9}
                  onChange={(e) => setSelectedQ9(e)}
                  name="gender"
                  options={[
                    { value: "Man", label: "Man" },
                    { value: "Kvinna", label: "Kvinna" },
                    { value: "Ickebinär", label: "Ickebinär" },
                  ]}
                  placeholder="Välj..."
                />
              </div>

              {isMobile && (
                <div
                  class="mb-5"
                  style={{
                    width: "100%",
                    border: "solid",
                    color: getComputedStyle(document.documentElement).getPropertyValue(
                      "--main-color"
                    ),
                  }}
                />
              )}

              <div class="form-outline mb-5">
                <Form.Label style={{ fontSize: "1.5rem" }}>
                  2. Mitt <span className="red-text"> Universitet/Högskola</span>
                  <p
                    style={{
                      fontSize: "1.05rem",
                      marginBottom: "0.2rem",
                      marginTop: "0.2rem",
                    }}
                  >
                    Välj ditt universitet/högskola i textrutan nedan. Hittar du inte ditt
                    lärosäte så funkar inte Stipendly för dig för tillfället!
                  </p>
                </Form.Label>
                <Select
                  value={selectedQ1}
                  onChange={(e) => setSelectedQ1(e)}
                  name="universities"
                  options={universities}
                  placeholder="Välj..."
                />
              </div>

              {isMobile && (
                <div
                  class="mb-5"
                  style={{
                    width: "100%",
                    border: "solid",
                    color: getComputedStyle(document.documentElement).getPropertyValue(
                      "--main-color"
                    ),
                  }}
                />
              )}
              <div class="form-outline mb-5">
                <Form.Label style={{ fontSize: "1.5rem" }}>
                  3. Min <span className="red-text"> Fakultet</span>
                  <p
                    style={{
                      fontSize: "1.05rem",
                      marginBottom: "0.2rem",
                      marginTop: "0.2rem",
                    }}
                  >
                    Hittar du inte det alternativ som passar in på dig är det ingen fara.
                    Välj då "Annat/Inte Applicerbart".
                  </p>
                </Form.Label>
                <Select
                  value={selectedQ2}
                  onChange={(e) => setSelectedQ2(e)}
                  name="fakultet"
                  options={fakultet}
                  placeholder="Välj..."
                  isOptionDisabled={(option) => option.disabled}
                />
              </div>

              {isMobile && (
                <div
                  class="mb-5"
                  style={{
                    width: "100%",
                    border: "solid",
                    color: getComputedStyle(document.documentElement).getPropertyValue(
                      "--main-color"
                    ),
                  }}
                />
              )}

              <div class="form-outline mb-5">
                <Form.Label style={{ fontSize: "1.5rem" }}>
                  4. Mina <span className="red-text"> Kommuner</span> som jag har koppling
                  till
                  <p
                    style={{
                      fontSize: "1.05rem",
                      marginBottom: "0.2rem",
                      marginTop: "0.2rem",
                    }}
                  >
                    Med koppling menas exempelvis om du bor eller har bott där, studerar
                    eller har studerat där, eller liknande. Välj upp till 3 kommuner om du
                    upplever att du har koppling till flera kommuner.
                  </p>
                </Form.Label>
                <Select
                  isMulti
                  value={selectedQ3}
                  onChange={(e) => setSelectedQ3(e)}
                  name="colors"
                  options={kommuner}
                  // only allow user to choose up to 3 options
                  isOptionDisabled={(option) => selectedQ3.length >= 3 || option.disabled}
                  className="basic-multi-select"
                  placeholder="Välj..."
                />
              </div>

              {isMobile && (
                <div
                  class="mb-5"
                  style={{
                    width: "100%",
                    border: "solid",
                    color: getComputedStyle(document.documentElement).getPropertyValue(
                      "--main-color"
                    ),
                  }}
                />
              )}

              <div class="form-outline mb-5">
                <Form.Label style={{ fontSize: "1.5rem" }}>
                  5. Min <span className="red-text"> Kommun</span> där jag gick på
                  gymnasium
                  <p
                    style={{
                      fontSize: "1.05rem",
                      marginBottom: "0.2rem",
                      marginTop: "0.2rem",
                    }}
                  >
                    Kommunen där ditt gymnasium låg. Välj upp till 3 kommuner om du har
                    gått på flera gymnasium i olika kommuner.
                  </p>
                </Form.Label>
                <Select
                  isMulti
                  value={selectedQ4}
                  onChange={(e) => setSelectedQ4(e)}
                  name="colors"
                  options={kommuner}
                  // only allow user to choose up to 3 options
                  isOptionDisabled={(option) => selectedQ4.length >= 3 || option.disabled}
                  className="basic-multi-select"
                  placeholder="Välj..."
                />
              </div>

              {isMobile && (
                <div
                  class="mb-5"
                  style={{
                    width: "100%",
                    border: "solid",
                    color: getComputedStyle(document.documentElement).getPropertyValue(
                      "--main-color"
                    ),
                  }}
                />
              )}

              <div class="form-outline mb-5">
                <Form.Label style={{ fontSize: "1.5rem" }}>
                  6. Min <span className="red-text"> Kommun</span> där jag gick på
                  grundskola
                  <p
                    style={{
                      fontSize: "1.05rem",
                      marginBottom: "0.2rem",
                      marginTop: "0.2rem",
                    }}
                  >
                    Kommunen där din grundskola låg. Välj upp till 3 kommuner om du har
                    gått på flera grundskolor i olika kommuner.
                  </p>
                </Form.Label>
                <Select
                  isMulti
                  value={selectedQ5}
                  onChange={(e) => setSelectedQ5(e)}
                  name="colors"
                  options={kommuner}
                  // only allow user to choose up to 3 options
                  className="basic-multi-select"
                  placeholder="Välj..."
                  isOptionDisabled={(option) => selectedQ5.length >= 3 || option.disabled}
                />
              </div>

              {isMobile && (
                <div
                  class="mb-5"
                  style={{
                    width: "100%",
                    border: "solid",
                    color: getComputedStyle(document.documentElement).getPropertyValue(
                      "--main-color"
                    ),
                  }}
                />
              )}

              <div class="form-outline mb-5">
                <Form.Label style={{ fontSize: "1.5rem" }}>
                  7. Mina <span className="red-text"> Län</span> som jag har koppling till
                  <p
                    style={{
                      fontSize: "1.05rem",
                      marginBottom: "0.2rem",
                      marginTop: "0.2rem",
                    }}
                  >
                    Med koppling menas exempelvis om du bor eller har bott där, studerar
                    eller har studerat där, eller liknande. Välj upp till 3 län om du
                    upplever att du har koppling till flera län.
                  </p>
                </Form.Label>
                <Select
                  isMulti
                  value={selectedQ6}
                  onChange={(e) => setSelectedQ6(e)}
                  name="lan"
                  options={lan}
                  // only allow user to choose up to 3 options
                  isOptionDisabled={() => selectedQ6.length >= 3}
                  className="basic-multi-select"
                  placeholder="Välj..."
                />
              </div>

              {isMobile && (
                <div
                  class="mb-5"
                  style={{
                    width: "100%",
                    border: "solid",
                    color: getComputedStyle(document.documentElement).getPropertyValue(
                      "--main-color"
                    ),
                  }}
                />
              )}

              <div class="form-outline mb-5">
                <Form.Label style={{ fontSize: "1.5rem" }}>
                  8. Mitt <span className="red-text"> Studieområde</span>
                  <p
                    style={{
                      fontSize: "1.05rem",
                      marginBottom: "0.2rem",
                      marginTop: "0.2rem",
                    }}
                  >
                    Området jag studerar inom. Här ska man inte tveka att ta flera
                    alternativ om det passar. Välj upp till 3 studieområden som du
                    upplever att dina studier stämmer in på (helt eller delvis).
                  </p>
                </Form.Label>
                <Select
                  isMulti
                  value={selectedQ7}
                  onChange={(e) => setSelectedQ7(e)}
                  name="Q7"
                  options={studieomrade}
                  // only allow user to choose up to 3 options
                  isOptionDisabled={(option) => selectedQ7.length >= 3 || option.disabled}
                  className="basic-multi-select"
                  placeholder="Välj..."
                />
              </div>

              {isMobile && (
                <div
                  class="mb-5"
                  style={{
                    width: "100%",
                    border: "solid",
                    color: getComputedStyle(document.documentElement).getPropertyValue(
                      "--main-color"
                    ),
                  }}
                />
              )}

              <div class="form-outline mb-5">
                <Form.Label style={{ fontSize: "1.5rem" }}>
                  9. Min <span className="red-text"> Kommun</span> där jag studerar
                  <p
                    style={{
                      fontSize: "1.05rem",
                      marginBottom: "0.2rem",
                      marginTop: "0.2rem",
                    }}
                  >
                    Kommunen där jag går mina kurser. Studerar du på distans, välj
                    kommunen där ditt lärosäte ligger.
                  </p>
                </Form.Label>
                <Select
                  isClearable={true}
                  value={selectedQ8}
                  onChange={(e) => setSelectedQ8(e)}
                  name="Q8"
                  options={kommuner}
                  // only allow user to choose up to 3 options
                  placeholder="Välj..."
                  isOptionDisabled={(option) => option.disabled}
                />
              </div>

              {isMobile && (
                <div
                  class="mb-5"
                  style={{
                    width: "100%",
                    border: "solid",
                    color: getComputedStyle(document.documentElement).getPropertyValue(
                      "--main-color"
                    ),
                  }}
                />
              )}

              <div class="form-outline mb-5">
                <Form.Label style={{ fontSize: "1.5rem" }}>
                  10. Min <span className="red-text"> Kommun</span> där jag bor
                  <p
                    style={{
                      fontSize: "1.05rem",
                      marginBottom: "0.2rem",
                      marginTop: "0.2rem",
                    }}
                  >
                    Kommunen där jag är folkbokförd för tillfället.
                  </p>
                </Form.Label>
                <Select
                  isClearable={true}
                  value={selectedQ10}
                  onChange={(e) => setSelectedQ10(e)}
                  name="Q10"
                  options={kommuner}
                  // only allow user to choose up to 3 options
                  placeholder="Välj..."
                  isOptionDisabled={(option) => option.disabled}
                />
              </div>

              <div class="form-outline mb-5">
                <Form.Label style={{ fontSize: "1.5rem" }}>
                  11. Min <span className="red-text"> Kommun</span> där jag är född
                  <p
                    style={{
                      fontSize: "1.05rem",
                      marginBottom: "0.2rem",
                      marginTop: "0.2rem",
                    }}
                  >
                    Kommunen där jag föddes. Om du är född utomlands välj "Annat/Inte
                    Applicerbart".
                  </p>
                </Form.Label>
                <Select
                  isClearable={true}
                  value={selectedQ11}
                  onChange={(e) => setSelectedQ11(e)}
                  name="Q11"
                  options={kommuner}
                  // only allow user to choose up to 3 options
                  placeholder="Välj..."
                  isOptionDisabled={(option) => option.disabled}
                />
              </div>

              {isMobile && (
                <div
                  class="mb-5"
                  style={{
                    width: "100%",
                    border: "solid",
                    color: getComputedStyle(document.documentElement).getPropertyValue(
                      "--main-color"
                    ),
                  }}
                />
              )}

              <div class="form-outline mb-5">
                <Form.Label style={{ fontSize: "1.5rem" }}>
                  12. Ett <span className="red-text"> Ämne</span> som jag kan skriva
                  uppsats inom
                  <p
                    style={{
                      fontSize: "1.05rem",
                      marginBottom: "0.2rem",
                      marginTop: "0.2rem",
                    }}
                  >
                    Denna fråga finns med då vissa stipendier kräver att man skriver en
                    uppsats inom ett visst ämne.{" "}
                  </p>
                </Form.Label>
                <Select
                  isClearable={true}
                  isSearchable={false}
                  value={selectedQ15}
                  onChange={(e) => setSelectedQ15(e)}
                  options={uppsats}
                  placeholder="Välj..."
                  isOptionDisabled={(option) => option.disabled}
                />
              </div>

              {isMobile && (
                <div
                  class="mb-5"
                  style={{
                    width: "100%",
                    border: "solid",
                    color: getComputedStyle(document.documentElement).getPropertyValue(
                      "--main-color"
                    ),
                  }}
                />
              )}

              <div class="form-outline mb-5">
                <Form.Label style={{ fontSize: "1.5rem" }}>
                  13. Kommer jag att studera <span className="red-text"> Utomlands</span>?
                  <p
                    style={{
                      fontSize: "1.05rem",
                      marginBottom: "0.2rem",
                      marginTop: "0.2rem",
                    }}
                  >
                    Kommer jag exempelvis att åka på utbyte, studera en del av mina
                    studier utomlands eller studera en hel utbildning utomlands.
                  </p>
                </Form.Label>
                <Select
                  isClearable={true}
                  isSearchable={false}
                  value={selectedQ12}
                  onChange={(e) => setSelectedQ12(e)}
                  name="colors"
                  options={[
                    { value: "ja", label: "Ja" },
                    { value: "nej", label: "Nej" },
                  ]}
                  // only allow user to choose up to 3 options
                  placeholder="Välj..."
                />
              </div>

              {isMobile && (
                <div
                  class="mb-5"
                  style={{
                    width: "100%",
                    border: "solid",
                    color: getComputedStyle(document.documentElement).getPropertyValue(
                      "--main-color"
                    ),
                  }}
                />
              )}

              <div class="form-outline mb-5">
                <Form.Label style={{ fontSize: "1.5rem" }}>
                  14. Kommer jag att studera en{" "}
                  <span className="red-text"> Hel utbildning utomlands</span>?
                  <p
                    style={{
                      fontSize: "1.05rem",
                      marginBottom: "0.2rem",
                      marginTop: "0.2rem",
                    }}
                  >
                    Funderar du på att studera en hel utbildning utomlands, exempelvis ett
                    helt kandidatprogram eller masterprogram.
                  </p>
                </Form.Label>
                <Select
                  isClearable={true}
                  isSearchable={false}
                  value={selectedQ13}
                  onChange={(e) => setSelectedQ13(e)}
                  name="colors"
                  options={[
                    { value: "ja", label: "Ja" },
                    { value: "nej", label: "Nej" },
                  ]}
                  // only allow user to choose up to 3 options
                  placeholder="Välj..."
                />
              </div>

              {isMobile && (
                <div
                  class="mb-5"
                  style={{
                    width: "100%",
                    border: "solid",
                    color: getComputedStyle(document.documentElement).getPropertyValue(
                      "--main-color"
                    ),
                  }}
                />
              )}

              <div class="form-outline mb-5" style={{ zIndex: "100" }}>
                <Form.Label style={{ fontSize: "1.5rem" }}>
                  15.
                  <span className="red-text"> Länder</span> jag kan komma att studera i
                  <p
                    style={{
                      fontSize: "1.05rem",
                      marginBottom: "0.2rem",
                      marginTop: "0.2rem",
                    }}
                  >
                    Kan det bli aktuellt att du på studerar i någon av följande länder,
                    exempelvis genom utbytesstudier eller permanenta studier?
                  </p>
                </Form.Label>
                <Select
                  isMulti
                  value={selectedQ16}
                  onChange={(e) => setSelectedQ16(e)}
                  name="colors"
                  options={countries}
                  // only allow user to choose up to 3 options
                  isOptionDisabled={(option) =>
                    selectedQ16.length >= 3 || option.disabled
                  }
                  className="basic-multi-select"
                  placeholder="Välj..."
                />
              </div>

              {isMobile && (
                <div
                  class="mb-5"
                  style={{
                    width: "100%",
                    border: "solid",
                    color: getComputedStyle(document.documentElement).getPropertyValue(
                      "--main-color"
                    ),
                  }}
                />
              )}

              <div class="form-outline mb-5">
                <Form.Label style={{ fontSize: "1.5rem" }}>
                  16.
                  <span className="red-text"> Språk</span> under utlandsstudier
                  <p
                    style={{
                      fontSize: "1.05rem",
                      marginBottom: "0.2rem",
                      marginTop: "0.2rem",
                    }}
                  >
                    Om språket pratas i landet du ska studera i, tveka inte att välja
                    språket även om dina kurser är på exempelvis engelska.
                  </p>
                </Form.Label>
                <Select
                  isClearable={true}
                  isSearchable={false}
                  value={selectedQ14}
                  onChange={(e) => setSelectedQ14(e)}
                  options={[
                    {
                      value: "Annat/Inte applicerbart",
                      label: "Annat/Inte applicerbart",
                    },
                    {
                      value: "",
                      label: "--- Alternativ ---",
                      disabled: true,
                    },
                    { value: "Franska", label: "Franska" },
                    { value: "Japanska", label: "Japanska" },
                    { value: "Spanska", label: "Spanska" },
                    { value: "Tyska", label: "Tyska" },
                  ]}
                  isOptionDisabled={(option) => option.disabled}
                  placeholder="Välj..."
                />
              </div>

              {error && <Alert variant="danger">{error}</Alert>}

              <div
                style={{
                  background: getComputedStyle(document.documentElement).getPropertyValue(
                    "--comp-color"
                  ),
                  padding: "1rem",
                  borderRadius: "0.3rem",
                }}
              >
                <p style={{ marginBottom: "2rem" }}>
                  Det är helt gratis att bekräfta din profil med knappen nedan, och
                  därigenom gå vidare till nästa steg. Du förbinder dig inte till något
                  köp.
                </p>
                <Form.Group className="mb-4">
                  <Form.Check
                    required
                    label="Jag har fyllt i mina uppgifter korrekt och godkänner behandling av mina personuppgifter i enlighet med integritetspolicyn."
                    feedback="Måste fyllas i."
                    feedbackType="invalid"
                  />
                </Form.Group>
              </div>
              <div
                style={{
                  width: "0",
                  height: "0",
                  borderLeft: "20px solid transparent",
                  borderRight: "20px solid transparent",
                  borderTop: "20px solid #dcf2ff",
                  margin: "0 auto",
                  marginBottom: "0.5rem",
                }}
              />

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  disabled={loading}
                  type="submit"
                  variant="flat"
                  class="btn btn-primary btn-block"
                  className="button"
                  style={{
                    minWidth: "50%",
                    maxWidth: "70%",
                    fontSize: "1.2rem",
                    padding: "0.6rem",
                  }}
                >
                  Bekräfta
                </Button>
              </div>
            </div>
          </Form>
        </Card>
      </div>
    </>
  );
}
