import React, { useRef, useState } from "react";
import { useAuth } from "../contexts/AuthContext.js";
import { Form, Button, Card, Alert, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function Password() {
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const passwordOriginalRef = useRef();
  const { updatePassword, reauthenticateWithCredential } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      try {
        setError("");
        setLoading(true);
        await reauthenticateWithCredential(passwordOriginalRef.current.value);
        setValidated(true);

        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
          return setError("Lösenorden matchar inte");
        }

        const promises = [];
        setLoading(true);
        setError("");
        if (passwordRef.current.value) {
          promises.push(updatePassword(passwordRef.current.value));
        }

        Promise.all(promises)
          .then(() => {
            navigate("/");
          })
          .catch(() => {
            setError("Kunde inte uppdatera kontouppgifter");
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (e) {
        setError("Ditt nuvarande lösenord stämmer inte");
      }
    }

    setValidated(true);
    setLoading(false);
  }

  return (
    <div
      style={{
        minHeight: "70vh",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        position: "relative",
        flex: "1",
      }}
    >
      <Card
        style={{
          marginTop: "5rem",
          marginBottom: "5rem",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
          position: "relative",
          flex: "1",
          fontSize: "1.1rem",
          maxWidth: "35rem",
          width: "75vw",
          zIndex: "100",
          background: "#F8F8F8",
        }}
      >
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          style={{ maxWidth: "25rem", width: "75vw" }}
        >
          <div style={{ paddingTop: "5rem", paddingBottom: "5rem", zIndex: "10" }}>
            <div style={{ textAlign: "center", paddingBottom: "1rem" }}>
              <h3>Ändra Lösenord</h3>
            </div>
            {error && <Alert variant="danger">{error}</Alert>}

            <div class="form-outline mb-4">
              <Form.Group id="password" as={Col} md="20" controlId="validationCustom03">
                <Form.Label>Nuvarande lösenord</Form.Label>
                <Form.Control
                  type="password"
                  ref={passwordOriginalRef}
                  placeholder="Nuvarande lösenord"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Måste fyllas i.
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <div class="form-outline mb-4">
              <Form.Group id="password" as={Col} md="20" controlId="validationCustom03">
                <Form.Label>Nytt Lösenord</Form.Label>
                <Form.Control
                  type="password"
                  ref={passwordRef}
                  placeholder="Nytt lösenord"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Måste fyllas i.
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <div class="form-outline mb-4">
              <Form.Group
                id="password-confirm"
                as={Col}
                md="20"
                controlId="validationCustom04"
              >
                <Form.Label>Upprepa Lösenord</Form.Label>
                <Form.Control
                  type="password"
                  ref={passwordConfirmRef}
                  placeholder="Upprepa lösenord"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Måste fyllas i.
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <Button
              disabled={loading}
              type="submit"
              variant="flat"
              class="btn btn-primary btn-block mb-3"
              className="button"
            >
              Ändra lösenord
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
}
