import React, { useRef, useState } from "react";
import { useAuth } from "../contexts/AuthContext.js";
import { Form, Button, Alert, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { updateProfile } from "firebase/auth";

export default function Settings() {
  const emailRef = useRef();
  const nameRef = useRef();
  const { currentUser, updateEmail } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      const promises = [];
      setLoading(true);
      setError("");
      if (nameRef.current.value !== currentUser.displayName) {
        promises.push(
          updateProfile(currentUser, {
            displayName: nameRef.current.value,
          })
        );
      }
      if (emailRef.current.value !== currentUser.email) {
        promises.push(updateEmail(emailRef.current.value));
      }

      Promise.all(promises)
        .then(() => {
          navigate("/");
        })
        .catch(() => {
          setError("Kunde inte uppdatera personlig information");
        })
        .finally(() => {
          setLoading(false);
        });
    }
    setValidated(true);
    setLoading(false);
  }

  return (
    <div
      className="Register"
      style={{
        minHeight: "70vh",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        position: "relative",
        flex: "1",
      }}
    >
      <Card
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
          position: "relative",
          flex: "1",
          fontSize: "1.1rem",
          maxWidth: "35rem",
          width: "75vw",
          zIndex: "100",
          background: "#F8F8F8",
          marginTop:"0"
        }}
      >
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          style={{ maxWidth: "25rem", width: "75vw" }}
        >
          <div style={{ paddingTop: "5rem", paddingBottom: "5rem", zIndex: "10" }}>
            <div style={{ textAlign: "center", paddingBottom: "1rem" }}>
              <h3>Personlig information</h3>
            </div>
            {error && <Alert variant="danger">{error}</Alert>}

            <div class="form-outline mb-4">
              <Form.Group id="name" as={Col} md="20" controlId="validationCustom01">
                <Form.Label>Namn</Form.Label>
                <Form.Control
                  required
                  type="name"
                  ref={nameRef}
                  placeholder="Namn"
                  defaultValue={currentUser.displayName}
                />
                <Form.Control.Feedback type="invalid">
                  Måste fyllas i.
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <div class="form-outline mb-4">
              <Form.Group id="email" as={Col} md="20" controlId="validationCustom02">
                <Form.Label>E-post</Form.Label>
                <Form.Control
                  required
                  type="email"
                  ref={emailRef}
                  placeholder="E-post"
                  defaultValue={currentUser.email}
                />
                <Form.Control.Feedback type="invalid">
                  Måste fyllas i.
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <Button
              disabled={loading}
              type="submit"
              variant="flat"
              class="btn btn-primary btn-block mb-3"
              className="button"
            >
              Ändra information
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
}
