import React from "react";
import logoBlue from "../images/logo-blue.png";
import "../App.css";
import { Facebook, Instagram } from "react-bootstrap-icons";

export default function FooterBrowser() {
  return (
    <div
      class="container"
      style={{
        background: getComputedStyle(document.documentElement).getPropertyValue(
          "--comp-color"
        ),
        marginLeft: "0px",
        marginRight: "0px",
        marginBottom: "0px",
        width: "100%",
        maxWidth: "100%",
        display: "flex",
        justifyContent: "space-around",
      }}
    >
      <footer
        class="d-flex flex-wrap justify-content-between align-items-center py-3 border-top"
        style={{
          width: "95%",
          maxWidth: "1400px",
          color: "#253e5c",
          fontWeight: "500",
        }}
      >
        <p class="col-md-5 mb-0">© Stipendly 2023. Alla rättigheter förbehållna.</p>

        <div class="col-md-2 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
          <a
            href="/"
            style={{
              textDecoration: "none",
              color: getComputedStyle(document.documentElement).getPropertyValue(
                "--main-color"
              ),
              fontSize: "1.5rem",
            }}
          >
            <img
              src={logoBlue}
              width="60vw"
              className="d-inline-block align-center"
              alt=""
              style={{
                marginRight: "5px",
                padding: "0px",
              }}
            />
            Stipendly
          </a>
        </div>

        <ul class="nav col-md-5 justify-content-end ">
          <li class="nav-item">
            <a
              href="https://stipendly.se/kopvillkor/"
              rel="noopener"
              class="nav-link px-2"
              style={{ color: "#253e5c" }}
            >
              Köpvillkor
            </a>
          </li>
          <li class="nav-item">
            <a
              href="https://stipendly.se/fragor-och-svar/"
              rel="noopener"
              class="nav-link px-2"
              style={{ color: "#253e5c " }}
            >
              Frågor och svar
            </a>
          </li>
          <li class="nav-item">
            <a
              href="https://stipendly.se/blogg/"
              rel="noopener"
              class="nav-link px-2 "
              style={{ color: "#253e5c" }}
            >
              Blogg
            </a>
          </li>
          <li class="nav-item">
            <a
              href="https://stipendly.se/kontakt/"
              rel="noopener"
              class="nav-link px-2 "
              style={{ color: "#253e5c" }}
            >
              Kontakt
            </a>
          </li>

          <a rel="noopener" href="https://www.facebook.com/stipendly">
            <Facebook
              color={"#253e5c"}
              size={40}
              style={{
                marginLeft: "0.5rem",
                marginRight: "0.25rem",
                cursor: "pointer",
              }}
            />
          </a>
          <a rel="noopener" href="https://www.instagram.com/stipendly.se/">
            <Instagram
              size={40}
              color={"#253e5c"}
              style={{ marginLeft: "0.5rem", cursor: "pointer" }}
            />
          </a>
        </ul>
      </footer>
    </div>
  );
}

export function FooterMobile() {
  return (
    <div
      class="container"
      style={{
        background: getComputedStyle(document.documentElement).getPropertyValue(
          "--comp-color"
        ),
        marginLeft: "0px",
        marginRight: "0px",
        marginBottom: "0px",
        width: "100%",
        maxWidth: "100%",
        display: "flex",
        justifyContent: "space-around",
      }}
    >
      <footer
        class="d-flex flex-wrap justify-content-center align-items-center py-3 border-top"
        style={{
          width: "95%",
          maxWidth: "1400px",
          color: "#253e5c",
          fontWeight: "500",
          textAlign: "center",
          fontSize: "1.1rem",
          fontFamily: "Poppins",
        }}
      >
        <div class="col-md-2 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
          <a
            href="/"
            style={{
              textDecoration: "none",
              color: getComputedStyle(document.documentElement).getPropertyValue(
                "--main-color"
              ),
              fontSize: "1.5rem",
            }}
          >
            <img
              src={logoBlue}
              width="60vw"
              className="d-inline-block align-center"
              alt=""
              style={{
                marginRight: "5px",
                padding: "0px",
              }}
            />
            Stipendly
          </a>
        </div>

        <ul class="nav col-md-5 justify-content-end ">
          <li class="nav-item" style={{ width: "100%" }}>
            <a
              href="https://stipendly.se/kopvillkor/"
              rel="noopener"
              class="nav-link px-2"
              style={{ color: "#253e5c" }}
            >
              Köpvillkor
            </a>
          </li>
          <li class="nav-item" style={{ width: "100%" }}>
            <a
              href="https://stipendly.se/fragor-och-svar/"
              rel="noopener"
              class="nav-link px-2"
              style={{ color: "#253e5c " }}
            >
              Frågor och svar
            </a>
          </li>
          <li class="nav-item" style={{ width: "100%" }}>
            <a
              href="https://stipendly.se/blogg/"
              rel="noopener"
              class="nav-link px-2 "
              style={{ color: "#253e5c" }}
            >
              Blogg
            </a>
          </li>
          <li class="nav-item" style={{ width: "100%" }}>
            <a
              href="https://stipendly.se/kontakt/"
              rel="noopener"
              class="nav-link px-2 "
              style={{ color: "#253e5c" }}
            >
              Kontakt
            </a>
          </li>

          <li class="nav-item mt-4 " style={{ width: "100%" }}>
            <a rel="noopener" href="https://www.facebook.com/stipendly">
              <Facebook
                color={"#253e5c"}
                size={40}
                style={{
                  marginLeft: "0.5rem",
                  marginRight: "1rem",
                  cursor: "pointer",
                }}
              />
            </a>
            <a rel="noopener" href="https://www.instagram.com/stipendly.se/">
              <Instagram
                size={40}
                color={"#253e5c"}
                style={{ marginLeft: "1rem", cursor: "pointer" }}
              />
            </a>
          </li>
        </ul>
        <p class="col-md-5 mb-1 mt-4">© Stipendly 2023. Alla rättigheter förbehållna.</p>
      </footer>
    </div>
  );
}
