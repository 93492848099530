import React from "react";
import { Toast } from "react-bootstrap";
import { isMobile, isTablet } from "react-device-detect";
import "../App.css";

export default function Start({ show, setShow, from, h1Text, pText, icon, payed }) {
  const toggleShow = () => setShow(!show);

  return (
    <Toast
      show={show}
      onClose={toggleShow}
      className={"info-toast"}
      style={{
        width: isTablet ? "50%" : isMobile ? "93%" : from === "Dashboard" && payed === 1 ? "62%" : "50%",
        opacity: "100%",
      }}
    >
      {/* <CloseButton
        onClick={toggleShow}
        style={{ position: "absolute", top: 20, right: 20, fontSize: "1.2rem" }}
      /> */}
      <Toast.Body style={{ padding: "0" }}>
        {icon}
        <h1
          style={{
            marginTop: "1.5rem",
            marginBottom: from === "Dashboard" && payed === 1 ? "1rem" : "2rem",
          }}
        >
          {h1Text} <br />
        </h1>

        <p style={{ fontSize: "1.2rem" }}>{pText}</p>
      </Toast.Body>
    </Toast>
  );
}
