import React, { useEffect, useState } from "react";
import { Spinner, Toast } from "react-bootstrap";
import { CreditCard } from "react-bootstrap-icons";
import "../App.css";

export default function ToPayment() {
  const [showLink, setShowLink] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    const redirect = setTimeout(() => {
      window.location.href = "https://buy.stripe.com/9AQ003eMF90601i7ss";
    }, 3000);

    setTimeout(() => {
      setShowLink(true);
    }, 6000);

    return () => clearTimeout(redirect);
  }, []);

  return (
    <div
      className="Register"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Toast
        className={"info-toast"}
        style={{
          maxWidth: "50rem",
          width: "90vw",
          opacity: "100%",
        }}
      >
        <Toast.Body style={{ padding: "0" }}>
          <CreditCard size={80} />
          <h1 style={{ marginTop: "1.5rem", marginBottom: "2rem" }}>
            Du blir nu skickad till{" "}
            <span className="red-text" style={{ marginRight: "1rem" }}>
              betalning
            </span>
            <Spinner animation="border" role="status" style={{ fontSize: "1rem"}}>
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <br />
          </h1>
          {showLink && (
            <p style={{ fontSize: "1.2rem" }}>
              Händer det inget?{" "}
              <a href="https://buy.stripe.com/test_28odQQ5pA5av3TyeUU?client_reference_id=123123123">
                Klicka här för att gå vidare.
              </a>
            </p>
          )}
        </Toast.Body>
      </Toast>
    </div>
  );
}
